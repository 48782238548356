import React from "react";

import cx from "classnames";

import "./Checkbox.css";
import isNoChecked from "../../assets/images/isNoChecked.svg"
import isCheckedIcon from "../../assets/images/isChecked.svg"

export default function Checkbox(props) {
  const { isChecked, setIsChecked, disabled, className } = props;

  return (
    <div
      className={cx("Checkbox", { disabled, selected: isChecked }, className)}
      onClick={() => setIsChecked(!isChecked)}
    >
      <span className="Checkbox-icon-wrapper">
        {
          isChecked ?
            <img src={isCheckedIcon} height="20px" />
            :
            <img src={isNoChecked} height="20px" />
        }
      </span>
      <span className="Checkbox-label ml-16 font-14 font-weight-6">{props.children}</span>
    </div>
  );
}
