import { createReducer } from "@reduxjs/toolkit";
import { priceList, priceList_gns } from "./actions";

type HttpState = {
  price?: {
    ETHUSDT: string;
  };
  price_gns?: any;
};

const initialState: HttpState = {
  price: undefined,
  price_gns: undefined,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(priceList, (state, { payload: data }) => {
      state.price = data;
    })
    .addCase(priceList_gns, (state, { payload: data }) => {
      state.price_gns = data;
    })
);
