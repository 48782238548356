import React from "react";

import cx from "classnames";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import { red } from "@material-ui/core/colors";

const UserFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  margin-right: 0;
  background: rgba(0, 0, 0, 0.25);
`;
const IOSSwitch = styled(props => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 38,
    height: 20,
    padding: 0,
    borderRadius: 4,
    margin: "0 !important",
    ".MuiFormControlLabel-root": {
      marginRight: 0,
    },
    "& .MuiSwitch-root": {
      marginRight: 0,
      margin: " 0 !important",
      color: "red",
    },
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      color: "#9B96FF",
      background: 'linear-gradient(128.7deg, #9B96FF 0 %, #5E58DB 56.9 %)',

      "&.Mui-checked": {
        transform: "translateX(17px)",
        color: "#505050",
        "& + .MuiSwitch-track": {
          backgroundColor: "#212121",
          opacity: 1,
          border: 0,
          borderRadius: 4,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      borderRadius: "4px",
      width: 16,
      height: 16,
    },
    "& .MuiSwitch-track": {
      borderRadius: 4,
      backgroundColor: "rgba(0, 0, 0, 0.25);",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }),
);

export default function Switchs(props) {
  const { isChecked, setIsChecked, disabled, className } = props;

  return (
    <div
      className={cx("Checkbox", { disabled, selected: isChecked }, className)}
      onClick={() => setIsChecked(!isChecked)}
    >
      <span className="Checkbox-icon-wrapper">
        {/* {isChecked && <ImCheckboxChecked className="App-icon Checkbox-icon active" />}
        {!isChecked && <ImCheckboxUnchecked className="App-icon Checkbox-icon inactive" />} */}
        <UserFormControlLabel
          className="fromcont"
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked={!isChecked} checked={!isChecked} />}
        />
      </span>

      <span className="Checkbox-label">{props.children}</span>
    </div>
  );
}
