import '../components/Messages/messages.scss'
import Waiting from "../assets/images/loading/waitingForConfirmation.svg";
import Processing from "../assets/images/loading/processingTransaction.svg";
import Fail from "../assets/images/failMsg.svg";
import Success from "../assets/images/successMsg.svg";
import View from "../assets/images/view.svg";
import Success2 from "../assets/images/successMsg2.svg";
import X from "../assets/images/x.svg";
import { helperToast } from './Helpers'
import { toast } from "react-toastify";
import { Box } from '@material-ui/core'

const handleClose = () => {
  toast.dismiss();
}

export const toastError = (message) => {
  helperToast.error(
    <div className="toastBox">
      <Box display="flex" justifyContent="space-between" alignItems="center" className='toastHeader'>
        <div className="font-14 font-weight-6 color9">Oh snap!</div>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
      <Box display="flex" alignItems="flex-start" className='toastInfo'>
        <Box display="flex" alignItems="center" justifyContent='center' className="iconBg">
          <img src={Fail} height={46} />
        </Box>
        <div className="message flex-1 ml-16 font-16 font-weight-4">
          {message}
        </div>
      </Box>
    </div>
  );
}

export const toastSuccess = (message) => {
  helperToast.success(
    <div className="toastBox">
      <Box display="flex" justifyContent="space-between" alignItems="center" className='toastHeader'>
        <div className="font-14 font-weight-6 color9">Well done!</div>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
      <Box display="flex" alignItems="flex-start" className='toastInfo'>
        <Box display="flex" alignItems="center" justifyContent='center' className="iconBg">
          <img src={Success} height={46} />
        </Box>
        <div className="message flex-1 ml-16 font-16 font-weight-4">
          {message}
        </div>
      </Box>
    </div>
  );
}

export const toastWaiting = (message) => {
  helperToast.waiting(
    <div className="toastBox">
      <Box display="flex" justifyContent="space-between" alignItems="center" className='toastHeader'>
        <div className="font-14 font-weight-6 color9">Waiting for confirmation</div>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
      <Box display="flex" alignItems="flex-start" className='toastInfo'>
        <Box display="flex" alignItems="center" justifyContent='center' className="iconBg">
          <img src={Waiting} height={46} />
        </Box>
        <div className="message flex-1 ml-16 font-16 font-weight-4">
          {message}
        </div>
      </Box>
    </div>
  )
}

export const toastProcessing = (message) => {
  helperToast.processing(
    <div className="toastBox processingBox">
      <Box display="flex" justifyContent="space-between" alignItems="center" className='toastHeader'>
        <div className="font-14 font-weight-6 color9">Processing transaction</div>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
      <Box display="flex" alignItems="flex-start" className='toastInfo'>
        <Box display="flex" alignItems="center" justifyContent='center' className="iconBg">
          <img src={Processing} height={46} />
        </Box>
        <div className="message flex-1 ml-16 font-16 font-weight-4">
          {message}
        </div>
      </Box>
    </div>
  )
}

export const toastTransaction = (title, url, message) => {
  helperToast.transaction(
    <div className="transactionToast">
      <Box display="flex" justifyContent="space-between" alignItems="center" className='toastHeader'>
        <div className="font-14 font-weight-6 color9">Processing transaction</div>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
      <Box display="flex" alignItems="flex-start" className='toastInfo'>
        <Box display="flex" alignItems="center" justifyContent='center' className="iconBg">
          <img src={Success2} height={46} />
        </Box>
        <div className="message flex-1 ml-16 font-16 font-weight-4">
          {message}
        </div>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" className="mt-16">
        <img src={View} />
        <a href={url} target="_blank" className="ml-12 color18 font-14">
          View On BscScan
        </a>
      </Box>
    </div>
  )
}

export const toastTransaction2 = (title, url, message) => {
  helperToast.transactionUpdate(
    <div className="transactionToast">
      <Box display="flex" justifyContent="flex-end">
        <img src={X} onClick={handleClose} />
      </Box>
      <Box display="flex" alignItems="flex-start" className='toastInfo'>
        <Box display="flex" alignItems="center" justifyContent='center' className="iconBg">
          <img src={Processing} height={46} />
        </Box>
        <div className="message flex-1 ml-16 font-16 font-weight-4">
          {message}
        </div>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" className="mt-16">
        <img src={View} />
        <a href={url} target="_blank" className="ml-12 color18 font-14">
          View On BscScan
        </a>
      </Box>
    </div>
  )
}  