import { setAll } from "../helpers";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { addresses } from "../configs/constants";
import { abi as IERC20ABI } from "../abis/IERC20.json";
import { abi as TokenVestingABI } from "../abis/TokenVesting_EDE.json";
import { abi as TokenVesting_pEDE_ABI } from "../abis/TokenVesting_pEDE.json";
import { ethers } from "ethers";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";

export const loadVestingDetails = createAsyncThunk(
  "vesting/loadVesting",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    console.log("loadVestingDetails");
    const signer = provider.getSigner();

    const TokenVestingContract_pEDE = new ethers.Contract(
      addresses[networkID].TokenVesting_pEDE,
      TokenVesting_pEDE_ABI,
      provider
    );
    const fixedDuration = await TokenVestingContract_pEDE.fixedDuration();
    const fixedDuration_day = fixedDuration / 3600 / 24;

    const pEDEContract = new ethers.Contract(
      addresses[networkID].pEDE,
      IERC20ABI,
      provider
    );
    const balance_pEDE = await pEDEContract.balanceOf(
      address || PLACEHOLDER_ACCOUNT
    );

    const TokenVestingContract_EDE = new ethers.Contract(
      addresses[networkID].TokenVesting_EDE,
      TokenVestingABI,
      signer
    );
    const claimable_aEDE = await TokenVestingContract_EDE.pendingPayoutFor(
      address || PLACEHOLDER_ACCOUNT
    );
    const vestingInfo_aEDE = await TokenVestingContract_EDE.vestingInfo(
      address || PLACEHOLDER_ACCOUNT
    );
    const vestingInfo_time1 = vestingInfo_aEDE[1];
    const vestingInfo_time2 = vestingInfo_aEDE[2];
    const currentVestingDuration =
      await TokenVestingContract_EDE.currentVestingDuration(
        address || PLACEHOLDER_ACCOUNT
      );
    const percentVestedRate_aEDE =
      await TokenVestingContract_EDE.percentVestedFor(
        address || PLACEHOLDER_ACCOUNT
      );
    const claimable_pEDE = await TokenVestingContract_pEDE.pendingPayoutFor(
      address || PLACEHOLDER_ACCOUNT
    );
    const vestingInfo_pEDE = await TokenVestingContract_pEDE.vestingInfo(
      address || PLACEHOLDER_ACCOUNT
    );
    const vestingInfo_time3 = vestingInfo_pEDE[1];
    const vestingInfo_time4 = vestingInfo_pEDE[2];
    const percentVestedRate_pEDE =
      await TokenVestingContract_pEDE.percentVestedFor(
        address || PLACEHOLDER_ACCOUNT
      );

    return {
      fixedDuration_day,
      balance_pEDE: ethers.utils.formatEther(balance_pEDE),
      pending_aEDE: ethers.utils.formatEther(vestingInfo_aEDE[0]),
      percentVestedRate_aEDE: ethers.utils.formatUnits(
        percentVestedRate_aEDE,
        7
      ),
      claimable_aEDE: ethers.utils.formatEther(claimable_aEDE),
      vestingInfo_time1,
      vestingInfo_time2,
      claimable_pEDE: ethers.utils.formatEther(claimable_pEDE),
      vestingInfo_time3,
      vestingInfo_time4,
      pending_pEDE: ethers.utils.formatEther(vestingInfo_pEDE[0]),
      percentVestedRate_pEDE: ethers.utils.formatUnits(
        percentVestedRate_pEDE,
        7
      ),
      currentVestingDuration,
    };
  }
);

const initialState = {
  loading: false,
};

const vestingSlice = createSlice({
  name: "vesting",
  initialState,
  reducers: {
    fetchVestingSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadVestingDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadVestingDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadVestingDetails.rejected, (state, {}) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.vesting;
export default vestingSlice.reducer;
export const { fetchVestingSuccess } = vestingSlice.actions;
export const getVestingState = createSelector(baseInfo, (vesting) => vesting);
