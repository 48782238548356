import { setAll } from "../helpers";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { addresses } from "../configs/constants";
import { ethers } from "ethers";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { abi as EUSDABI } from "../abis/EUSD.json";

export const loadEusdDetails = createAsyncThunk(
  "eusd/loadEusd",
  async ({ networkID, provider }: IBaseAddressAsyncThunk) => {
    console.log("loadEusdDetails");

    const RewardRouterContract = new ethers.Contract(
      addresses[networkID].RewardRouter,
      RewardRouterABI,
      provider
    );
    const EUSDPoolInfo = await RewardRouterContract.getEUSDPoolInfo();
    const totalCollateral = ethers.utils.formatUnits(EUSDPoolInfo[0], 30);
    const circulatingSupply = ethers.utils.formatUnits(EUSDPoolInfo[2], 18);
    const buyFees = EUSDPoolInfo[4];
    const sellFees = EUSDPoolInfo[5];
    const collateralRatio = Number(totalCollateral) / Number(circulatingSupply);

    // const CustomBondContract = new ethers.Contract(addresses[networkID].CustomBond, CustomBondABI, provider)
    // const discountRate = await CustomBondContract.discountRate()
    // const percentVestedFor = await CustomBondContract.percentVestedFor(address || PLACEHOLDER_ACCOUNT)
    // const claimable = await CustomBondContract.pendingPayoutFor(address || PLACEHOLDER_ACCOUNT)
    // const bondInfo = await CustomBondContract.bondInfo(address || PLACEHOLDER_ACCOUNT)
    // const totalLocked = bondInfo[0]
    const percentVestedFor = 0;
    const claimable = 0;
    const totalLocked = 0;

    const EUSDContract = new ethers.Contract(
      addresses[networkID].EUSD,
      EUSDABI,
      provider
    );
    const balancePool_eusd = await EUSDContract.balanceOf(
      addresses[networkID].RewardRouter
    );
    const totalSupply_eusd = await EUSDContract.totalSupply();
    const totalStaked_eusd = 0; // await EUSDContract.balanceOf(addresses[networkID].EUSDStaking)
    const circLocked = Number(totalStaked_eusd) / Number(totalSupply_eusd);
    // const EUSDStakingContract = new ethers.Contract(addresses[networkID].EUSDStaking, EUSDStakingABI, provider)
    const apr = 0; //await EUSDStakingContract.stakeAPR()

    const capacity = 0; // await EDEontract.balanceOf(addresses[networkID].CustomTreasury)

    return {
      circLocked,
      // discountRate: discountRate.gte(BigNumber.from(1e6)) ? ethers.utils.formatUnits(discountRate.sub(BigNumber.from(1e6)), 6) : "0",
      discountRate: "0",
      apr: ethers.utils.formatUnits(apr, 12),
      capacity: ethers.utils.formatUnits(capacity, 18),
      percentVestedRate: ethers.utils.formatUnits(percentVestedFor, 4),
      totalStaked_eusd: ethers.utils.formatEther(totalStaked_eusd),
      claimable: ethers.utils.formatEther(claimable),
      totalLocked: ethers.utils.formatEther(totalLocked),
      totalCollateral,
      circulatingSupply,
      collateralRatio,
      buyFees,
      sellFees,
      balancePool_eusd: ethers.utils.formatEther(balancePool_eusd),
    };
  }
);

const initialState = {
  loading: false,
};

const eusdSlice = createSlice({
  name: "eusd",
  initialState,
  reducers: {
    fetchEusdSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEusdDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadEusdDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadEusdDetails.rejected, (state, {}) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.eusd;
export default eusdSlice.reducer;
export const { fetchEusdSuccess } = eusdSlice.actions;
export const getEusdState = createSelector(baseInfo, (eusd) => eusd);
