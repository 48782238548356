import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const InterestProgress = withStyles({
  root: {
    color: "#FA5341",
    height: "4px",
    width: "130px",
    padding: 0
  },
  thumb: {
    height: "4px",
    width: "4px",
    backgroundColor: "#FA5341",
    display: 'none'
  },
  active: {
    backgroundColor: "#0AD5BD"
  },
  disabled: {
    color: "#0AD5BD!important"
  },
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: "4px",
    borderRadius: "3px",
  },
  rail: {
    height: "4px",
    borderRadius: "3px",
    backgroundColor: "#FA5341",
    opacity: 1,
  },
})(Slider);