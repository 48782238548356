import { useCallback } from "react";
import { batch, useDispatch } from "react-redux";
import { addresses } from "src/configs/constants";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useWeb3Context } from ".";
import { ethers } from "ethers";
import { abi as FaucetABI } from "../abis/Faucet.json";
import { loadAccountDetails } from "../slices/AccountSlice";
import { serializeError } from "eth-rpc-errors";
import { SerializedEthereumRpcError } from "eth-rpc-errors/dist/classes";
import { toastError, toastSuccess } from "src/helpers/toastHelpers";

export default function useClaim() {
  const dispatch = useDispatch();
  const { chainID, address, provider } = useWeb3Context();
  const signer = provider.getSigner();

  const claimTokens = useCallback(async () => {
    if (chainID && provider && address) {
      let tx: any;
      try {
        const faucetContract = new ethers.Contract(
          addresses[chainID].faucet,
          FaucetABI,
          signer
        );
        tx = await faucetContract.claim();
        dispatch(
          fetchPendingTxns({ txnHash: tx.hash, text: "claim", type: "claim" })
        );
        await tx.wait();
        return tx;
      } catch (e: any) {
        const rpcError: SerializedEthereumRpcError = serializeError(e);

        // @ts-ignore
        if (rpcError.data || rpcError.data.originalError) {
          const errs = rpcError.data as any;
          toastError(errs.originalError.reason);
        } else {
          toastError(rpcError.message);
        }
      } finally {
        if (tx) {
          batch(() => {
            dispatch(
              loadAccountDetails({ networkID: chainID, provider, address })
            );
            dispatch(clearPendingTxn(tx.hash));
            // dispatch(success("Claim Success!"))
            toastSuccess("Claim Success!");
          });
        }
      }
    }
  }, [provider, chainID, address, signer, dispatch]);

  return { claimTokens };
}
