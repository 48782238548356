import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Tooltip2 from "src/components/Tooltip/Tooltip";
export const HtmlTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(45, 34, 104, 0.4)",
    border: "1px solid rgba(255, 255, 255, 0.16)",
    color: "#DFE8F1",
    minWidth: "370px",
    boxShadow: " 0px 0px 16px rgba(0, 0, 0, 0.4)",
    backdropFilter: "blur(24px)",
    padding: "16px",
    borderRadius: "4px",
    fontSize: "14px",
  },
}));

export default function Tooltips({
  renderContent,
  children,
  className = "",
  position = "bottom",
}: any) {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  return (
    <>
      {isVerySmallScreen ? (
        <Tooltip2
          className={className}
          handle={children}
          position={position}
          renderContent={renderContent}
        ></Tooltip2>
      ) : (
        <HtmlTooltip className={className} title={renderContent}>
          <Box className={className}>{children}</Box>
        </HtmlTooltip>
      )}
    </>
  );
}
