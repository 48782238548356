import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const BuyOrSellBtn = styled(Button)({
  height: "40px",
  lineHeight: "40px",
  maxHeight: "40px",
  width: "120px",
  color: "#F89542",
  fontSize: 14,
  fontFamily: "Poppins-Bold",
  textTransform: "uppercase",
  background: "transparent",
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.16)",
  borderRadius: "4px",
  "&:hover": {
    background: "#f89542",
    color: "#ffffff",
  },
});
