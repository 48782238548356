import { useMemo } from "react";
import { Interface } from "@ethersproject/abi";
import { useWeb3Context } from "./web3Context";
import { useOrderBookReaderContract } from "./useContract";
import {
  PLACEHOLDER_ACCOUNT,
  parseDecreaseOrdersData,
  parseIncreaseOrdersData,
  parseSwapOrdersData,
} from "src/helpers/Helpers";
import { ELP_LIST } from "src/constants/tokens";
import { useMultipleContractMultipleData } from "src/lib/hooks/useMultipleContractMultipleData";
import { abi as OrderBookABI } from "../abis/OrderBook.json";
import { abi as OrderBookReaderABI } from "../abis/OrderBookReader.json";

const serverIndexes = { swap: [], increase: [], decrease: [] };

const getRange = (to: number, from?: number | undefined) => {
  const LIMIT = 10;
  const _indexes = [];
  from = from || Math.max(to - LIMIT, 0);
  for (let i = to - 1; i >= from; i--) {
    _indexes.push(i);
  }
  return _indexes;
};

const getIndexes = (knownIndexes: any[], lastIndex: number) => {
  if (knownIndexes.length === 0) {
    return getRange(lastIndex);
  }
  return [
    ...knownIndexes,
    ...getRange(lastIndex, knownIndexes[knownIndexes.length - 1] + 1).sort(
      (a, b) => b - a
    ),
  ];
};

const ORDERBOOK_INTERFACE = new Interface(OrderBookABI);
const ORDERBOOK_READER = new Interface(OrderBookReaderABI);

export const useAccountOrderList = () => {
  const { chainID, address } = useWeb3Context();
  const orderBookReaderContract = useOrderBookReaderContract();
  const elpList = ELP_LIST[chainID];
  const orderBook_address_arr = elpList.map((elp) => elp.orderBook_address);
  orderBookReaderContract?.getSwapOrders;
  const { orderBookAddressArr, paramArr } = useMemo(() => {
    const orderBookAddressArr: string[] = [];
    const paramArr: any = [];

    orderBook_address_arr.forEach((x) => {
      orderBookAddressArr.push(x);
      paramArr.push([[address ? address : PLACEHOLDER_ACCOUNT]]);
    });
    return {
      orderBookAddressArr,
      paramArr,
    };
  }, [address, orderBook_address_arr]);

  const swapOrdersIndex = useMultipleContractMultipleData(
    orderBookAddressArr,
    ORDERBOOK_INTERFACE,
    "swapOrdersIndex",
    paramArr
  );

  const increaseOrdersIndex = useMultipleContractMultipleData(
    orderBookAddressArr,
    ORDERBOOK_INTERFACE,
    "increaseOrdersIndex",
    paramArr
  );

  const decreaseOrdersIndex = useMultipleContractMultipleData(
    orderBookAddressArr,
    ORDERBOOK_INTERFACE,
    "decreaseOrdersIndex",
    paramArr
  );

  const { swapData, increaseData, decreaseData } = useMemo(() => {
    return orderBookAddressArr.reduce((pre: any, curr, index) => {
      const { result: swapOrders } = swapOrdersIndex[index][0];
      const { result: increaseOrders } = increaseOrdersIndex[index][0];
      const { result: decreaseOrders } = decreaseOrdersIndex[index][0];

      if (
        !swapOrders ||
        !increaseOrders ||
        !decreaseOrders ||
        !orderBookReaderContract
      )
        return pre;
      const indexes_swap = getIndexes(serverIndexes?.swap, swapOrders[0]);
      const indexes_increase = getIndexes(
        serverIndexes?.increase,
        increaseOrders[0]
      );
      const indexes_decrease = getIndexes(
        serverIndexes?.decrease,
        decreaseOrders[0]
      );
      const account = address ? address : PLACEHOLDER_ACCOUNT;
      const orderBookReaderAddr = orderBookReaderContract.address;

      if (pre.swapData) {
        // const indexes = getIndexes(knownIndexes, increaseOrders);
        pre.swapData.contract.push(orderBookReaderAddr);
        pre.swapData.data.push([[curr, account, indexes_swap]]);
        pre.swapData.index.push(indexes_swap);

        pre.increaseData.contract.push(orderBookReaderAddr);
        pre.increaseData.data.push([[curr, account, indexes_increase]]);
        pre.increaseData.index.push(indexes_increase);

        pre.decreaseData.contract.push(orderBookReaderAddr);
        pre.decreaseData.data.push([[curr, account, indexes_decrease]]);
        pre.decreaseData.index.push(indexes_decrease);
      } else {
        pre = {
          swapData: {
            contract: [orderBookReaderAddr],
            data: [[[curr, account, indexes_swap]]],
            index: [indexes_swap],
          },
          increaseData: {
            contract: [orderBookReaderAddr],
            data: [[[curr, account, indexes_increase]]],
            index: [indexes_increase],
          },
          decreaseData: {
            contract: [orderBookReaderAddr],
            data: [[[curr, account, indexes_decrease]]],
            index: [indexes_decrease],
          },
        };
      }
      return pre;
    }, {});
  }, [
    address,
    decreaseOrdersIndex,
    increaseOrdersIndex,
    orderBookAddressArr,
    orderBookReaderContract,
    swapOrdersIndex,
  ]);
  const swapOrders = useMultipleContractMultipleData(
    useMemo(
      () => (swapData?.contract ? swapData?.contract : [undefined]),
      [swapData]
    ),
    ORDERBOOK_READER,
    "getSwapOrders",
    swapData?.data ?? undefined
  );

  const increaseOrders = useMultipleContractMultipleData(
    useMemo(
      () => (increaseData?.contract ? increaseData?.contract : [undefined]),
      [increaseData]
    ),
    ORDERBOOK_READER,
    "getIncreaseOrders",
    increaseData?.data ?? undefined
  );
  const decreaseOrders = useMultipleContractMultipleData(
    useMemo(
      () => (decreaseData?.contract ? decreaseData?.contract : [undefined]),
      [decreaseData]
    ),
    ORDERBOOK_READER,
    "getDecreaseOrders",
    decreaseData?.data ?? undefined
  );

  return useMemo(() => {
    return orderBookAddressArr.reduce((pre: any, curr, index) => {
      if (
        swapOrders.length === 0 ||
        increaseOrders.length === 0 ||
        decreaseOrders.length === 0 ||
        !increaseData
      )
        return pre;

      const { result: swapOrderList }: any = swapOrders[index][0];
      const { result: increaseOrderList }: any = increaseOrders[index][0];
      const { result: decreaseOrderList }: any = decreaseOrders[index][0];
      if (!swapOrderList || !increaseOrderList || !decreaseOrderList)
        return pre;
      const swapOrdersData = parseSwapOrdersData(
        chainID,
        swapOrderList,
        address,
        swapData.index[index],
        curr
      );
      const increaseOrdersData = parseIncreaseOrdersData(
        chainID,
        increaseOrderList,
        address,
        increaseData.index[index],
        curr
      );
      const decreaseOrdersData = parseDecreaseOrdersData(
        chainID,
        decreaseOrderList,
        address,
        decreaseData.index[index],
        curr
      );
      const data = [
        ...swapOrdersData,
        ...increaseOrdersData,
        ...decreaseOrdersData,
      ];
      pre.push(...data);

      return pre;
    }, []);
  }, [
    orderBookAddressArr,
    swapOrders,
    increaseOrders,
    decreaseOrders,
    increaseData,
    chainID,
    address,
    swapData,
    decreaseData,
  ]);
};
