import { ethers } from "ethers";
const { AddressZero } = ethers.constants;

import { getConstant } from "../../configs/getConstant";
import Reader from "../../abis/ReaderV2.json";
import {
    FUNDING_RATE_PRECISION,
    BASIS_POINTS_DIVISOR,
    MARGIN_FEE_BASIS_POINTS, getTokenInfo,
    getPositionKey,
    getPositionContractKey,
    getLeverage,
    getDeltaStr,
    bigNumberify,
} from '../../helpers/Helpers'
import { getContractAddress } from '../../Addresses'
import useSWR from "swr";
import { useInfoTokens } from "src/Api";

const PENDING_POSITION_VALID_DURATION = 600 * 1000;
const UPDATED_POSITION_VALID_DURATION = 60 * 1000;

export const getPositionData = (connected, chainID, elpName, readerAddress, positionQuery, fetcher, provider) => {
    const vaultAddress1 = getContractAddress(chainID, "Vault")
    /* ELP-1 & ELP-2 */
    const { data: positionData1, error: positionDataError1 } = useSWR(
        connected && [connected + "ELP-1", chainID, readerAddress, "getPositions", vaultAddress1, address],
        {
            fetcher: fetcher(provider, Reader, [
                positionQuery.collateralTokens,
                positionQuery.indexTokens,
                positionQuery.isLong,
            ]),
            refreshInterval: 3000,
        },
    );
    if (chainID == 56 || chainID == 97) {
        const vaultAddress2 = getContractAddress(chainID, "Vault_ELP_2")
        const { data: positionData2, error: positionDataError2 } = useSWR(
            connected && [connected + "ELP-2", chainID, readerAddress, "getPositions", vaultAddress2, address],
            {
                fetcher: fetcher(provider, Reader, [
                    positionQuery.collateralTokens,
                    positionQuery.indexTokens,
                    positionQuery.isLong,
                ]),
                refreshInterval: 3000,
            },
        );
        // console.log("positionData2", positionData2);
        let positionData = elpName == "ELP-1" ? positionData1 : positionData2;
        if (positionData1 && positionData2) {
            positionData = [...positionData1, ...positionData2];
        }
        if (positionData1 && !positionData2) {
            positionData = positionData1;
        }
        if (!positionData1 && positionData2) {
            positionData = positionData2;
        }
        return positionData;
    }
    return positionData1;
}
export const getInfoTokens = (chainID, connected, elpName, readerAddress, tokenBalances1, provider, nativeTokenAddress, whitelistedTokenAddresses, fetcher) => {
    const vaultAddress1 = getContractAddress(chainID, "Vault")
    const { data: fundingRateInfo1 } = useSWR([connected + "ELP-1", chainID, positionReaderAddress, "getTokenInfo"], {
        fetcher: fetcher(provider, PositionReader, [vaultAddress1, nativeTokenAddress, whitelistedTokenAddresses]),
    });
    const infoTokens1 = getElpInfoTokens(
        "ELP-1",
        provider,
        chainID,
        connected,
        tokenBalances1,
        fundingRateInfo1)
    let currInfoTokens = infoTokens1
    if (chainID == 56 || chainID == 97) {
        const vaultAddress2 = getContractAddress(chainID, "Vault_ELP_2")
        const { data: fundingRateInfo2 } = useSWR([connected + "ELP-2", chainID, positionReaderAddress, "getTokenInfo"], {
            fetcher: fetcher(provider, PositionReader, [vaultAddress2, nativeTokenAddress, whitelistedTokenAddresses]),
        });
        const infoTokens2 = getElpInfoTokens(
            "ELP-2",
            provider,
            chainID,
            connected,
            tokenBalances2,
            fundingRateInfo2)
        currInfoTokens = elpName == "ELP-1" ? infoTokens1 : infoTokens2;
        const infoTokens_1 = {};
        for (let key in infoTokens1) {
            if (infoTokens1[key]?.isElp1) {
                infoTokens_1[key] = infoTokens1[key];
            }
        }
        const infoTokens_2 = {};
        for (let key in infoTokens2) {
            if (infoTokens2[key]?.isElp2) {
                infoTokens_2[key] = infoTokens2[key];
            }
        }
        const infoTokens = elpName == "ELP-1" ? { ...infoTokens_2, ...infoTokens_1 } : { ...infoTokens_1, ...infoTokens_2 };
        return { currInfoTokens, infoTokens }
    }
    return { currInfoTokens, infoTokens: infoTokens1 }
}

const getElpInfoTokens = (elpName, provider, chainID, connected, tokenBalances, fundingRateInfo) => {
    let { infoTokens } = useInfoTokens(
        elpName,
        provider,
        chainID,
        connected,
        tokenBalances,
        fundingRateInfo,
    );
    // console.log("---infoTokens---", infoTokens);
    return infoTokens;
}

function getFundingFee(data) {
    let { entryFundingRate, cumulativeFundingRate, size } = data;
    if (entryFundingRate && cumulativeFundingRate) {
        // console.log("cumulativeFundingRate", cumulativeFundingRate, "entryFundingRate", entryFundingRate, cumulativeFundingRate.sub(entryFundingRate), cumulativeFundingRate > entryFundingRate,
        //     ethers.utils.formatUnits(size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION), 30))
        return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    }
    return;
}

const getTokenAddress = (token, nativeTokenAddress) => {
    if (token.address === AddressZero) {
        return nativeTokenAddress;
    }
    return token.address;
};

function applyPendingChanges(position, pendingPositions) {
    if (!pendingPositions) {
        return;
    }
    const { key } = position;

    if (
        pendingPositions[key] &&
        pendingPositions[key].updatedAt &&
        pendingPositions[key].pendingChanges &&
        pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()
    ) {
        const { pendingChanges } = pendingPositions[key];
        if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
            return;
        }

        if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
            return;
        }

        position.hasPendingChanges = true;
        position.pendingChanges = pendingChanges;
    }
}

export function getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    includeDelta,
    showPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions
) {
    const propsLength = getConstant(chainId, "positionReaderPropsLength");
    const positions = [];
    const positionsMap = {};
    if (!positionData) {
        return { positions, positionsMap };
    }
    const { collateralTokens, indexTokens, isLong } = positionQuery;
    // console.log("collateralTokens", collateralTokens)
    for (let i = 0;i < collateralTokens.length;i++) {
        const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContractAddress(chainId, "NATIVE_TOKEN"));
        const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContractAddress(chainId, "NATIVE_TOKEN"));
        const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
        let contractKey;
        if (account) {
            contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
        }
        const position = {
            key,
            contractKey,
            collateralToken,
            indexToken,
            isLong: isLong[i],
            size: positionData[i * propsLength],
            collateral: positionData[i * propsLength + 1],
            averagePrice: positionData[i * propsLength + 2],
            entryFundingRate: positionData[i * propsLength + 3],
            cumulativeFundingRate: collateralToken.cumulativeFundingRate,
            hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
            realisedPnl: positionData[i * propsLength + 5],
            lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
            hasProfit: positionData[i * propsLength + 7].eq(1),
            delta: positionData[i * propsLength + 8],
            markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
        };
        if (
            updatedPositions &&
            updatedPositions[key] &&
            updatedPositions[key].updatedAt &&
            updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()
        ) {
            const updatedPosition = updatedPositions[key];
            position.size = updatedPosition.size;
            position.collateral = updatedPosition.collateral;
            position.averagePrice = updatedPosition.averagePrice;
            position.entryFundingRate = updatedPosition.entryFundingRate;
        }
        let fundingFee = getFundingFee(position);
        // console.log("fundingFee", fundingFee, "position", position)
        position.fundingFee = fundingFee ? fundingFee : bigNumberify(0);
        position.collateralAfterFee = position.collateral.sub(position.fundingFee);

        position.closingFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
        position.positionFee = position.size.mul(MARGIN_FEE_BASIS_POINTS).mul(2).div(BASIS_POINTS_DIVISOR);
        position.totalFees = position.positionFee.add(position.fundingFee);

        position.pendingDelta = position.delta;

        if (position.collateral.gt(0)) {
            position.hasLowCollateral =
                position.collateralAfterFee.lt(0) || position.size.div(position.collateralAfterFee.abs()).gt(50);

            if (position.averagePrice.gt(0) && position.markPrice) {
                const priceDelta = position.averagePrice.gt(position.markPrice)
                    ? position.averagePrice.sub(position.markPrice)
                    : position.markPrice.sub(position.averagePrice);
                position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice);

                position.delta = position.pendingDelta;

                if (position.isLong) {
                    position.hasProfit = position.markPrice.gte(position.averagePrice);
                } else {
                    position.hasProfit = position.markPrice.lte(position.averagePrice);
                }
            }

            position.deltaPercentage = position.pendingDelta.mul(BASIS_POINTS_DIVISOR).div(position.collateral);

            const { deltaStr, deltaPercentageStr } = getDeltaStr({
                delta: position.pendingDelta,
                deltaPercentage: position.deltaPercentage,
                hasProfit: position.hasProfit,
            });

            position.deltaStr = deltaStr;
            position.deltaPercentageStr = deltaPercentageStr;
            position.deltaBeforeFeesStr = deltaStr;

            let hasProfitAfterFees;
            let pendingDeltaAfterFees;

            if (position.hasProfit) {
                if (position.pendingDelta.gt(position.totalFees)) {
                    hasProfitAfterFees = true;
                    pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
                } else {
                    hasProfitAfterFees = false;
                    pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta);
                }
            } else {
                hasProfitAfterFees = false;
                pendingDeltaAfterFees = position.pendingDelta.add(position.totalFees);
            }

            position.hasProfitAfterFees = hasProfitAfterFees;
            position.pendingDeltaAfterFees = pendingDeltaAfterFees;
            position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
                .mul(BASIS_POINTS_DIVISOR)
                .div(position.collateral);

            const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
                delta: position.pendingDeltaAfterFees,
                deltaPercentage: position.deltaPercentageAfterFees,
                hasProfit: hasProfitAfterFees,
            });

            position.deltaAfterFeesStr = deltaAfterFeesStr;
            position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

            if (showPnlAfterFees) {
                position.deltaStr = position.deltaAfterFeesStr;
                position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
            }

            let netValue = positionData[i].collateral.sub(positionData[i].pendingPremiumFee).sub(positionData[i].pendingFundingFee)

            netValue = netValue.sub(position.fundingFee);

            if (showPnlAfterFees) {
                netValue = netValue.sub(position.closingFee);
            }

            position.netValue = netValue;
        }


        position.leverage = getLeverage({
            size: position.size,
            collateral: position.collateral,
            entryFundingRate: position.entryFundingRate,
            cumulativeFundingRate: position.cumulativeFundingRate,
            hasProfit: position.hasProfit,
            delta: position.delta,
            includeDelta,
        });

        positionsMap[key] = position;

        applyPendingChanges(position, pendingPositions);
        if (position.size.gt(0) || position.hasPendingChanges) {
            positions.push(position);
        }
    }
    return { positions, positionsMap };
}

export function getPositionQuery(tokens, nativeTokenAddress) {
    const collateralTokens = [];
    const indexTokens = [];
    const isLong = [];

    for (let i = 0;i < tokens.length;i++) {
        const token = tokens[i];
        if (token.isStable) {
            continue;
        }
        if (token.isWrapped) {
            continue;
        }
        collateralTokens.push(getTokenAddress(token, nativeTokenAddress));
        indexTokens.push(getTokenAddress(token, nativeTokenAddress));
        isLong.push(true);
    }

    for (let i = 0;i < tokens.length;i++) {
        const stableToken = tokens[i];
        if (!stableToken.isStable) {
            continue;
        }

        for (let j = 0;j < tokens.length;j++) {
            const token = tokens[j];
            if (token.isStable) {
                continue;
            }
            if (token.isWrapped) {
                continue;
            }
            collateralTokens.push(stableToken.address);
            indexTokens.push(getTokenAddress(token, nativeTokenAddress));
            isLong.push(false);
        }
    }

    return { collateralTokens, indexTokens, isLong };
}