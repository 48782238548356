import { Box, Dialog, useMediaQuery } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { ethers } from "ethers";
import { BsArrowRight } from "react-icons/bs";

import "../../views/dashboard/dialog.scss";
import Close from "../../assets/images/close.svg";
import PendingIcon from "../../assets/images/loading/pending.svg";
import {
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEPOSIT_FEE,
  DUST_BNB,
  formatAmount,
  bigNumberify,
  usePrevious,
  formatAmountFree,
  fetcher,
  parseValue,
  expandDecimals,
  shouldRaiseGasError,
  getTokenInfo,
  approveTokens,
  getLiquidationPrice2,
  getPositionSignature,
  getPositionSignaturegns,
} from "../../helpers/Helpers";
import { getContractAddress } from "../../Addresses";
import { callContract } from "../../Api";
import Tab from "src/components/Tab/Tab";
import PositionRouter from "../../abis/PositionRouter.json";
import Token from "../../abis/Token.json";
import { getConstant } from "../../configs/getConstant";
import { toastError } from "src/helpers/toastHelpers";
import {
  getPositionRouterAddress,
  getRouterAddress,
} from "src/helpers/elpAddress";
import USD from "src/assets/images/usd.svg";

import InputSelect from "src/components/InputSelect";
import Tooltips from "../Tooltips/totalTips";
import TooltipItem from "../TooltipItem";
import Column from "../Column";
import { ConfirmBtn } from "../Button/ConfirmBtn";
import { useGetELPData } from "src/hooks/useGetELPData";
import { useUpDataBlockNumber } from "src/lib/hooks/useUpDataBlockNumber";
import {
  useRouterSignContract,
  useInterfaceMulticall,
} from "src/hooks/useContract";
const DEPOSIT = "Deposit";
const WITHDRAW = "Withdraw";
const EDIT_OPTIONS = [DEPOSIT, WITHDRAW];
const { AddressZero } = ethers.constants;

export default function PositionEditor(props) {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const {
    elpName,
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    position,
    isVisible,
    setIsVisible,
    infoTokens,
    active,
    account,
    library,
    collateralTokenAddress,
    pendingTxns,
    setPendingTxns,
    getUsd,
    getLeverage,
    savedIsPnlInLeverage,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    chainId,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props;

  const handleClose = () => {
    setIsVisible(false);
  };
  const upDataBlock = useUpDataBlockNumber();
  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  // const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [option, setOption] = useState(DEPOSIT);
  const [fromValue, setFromValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const { routerAddress, positionRouterAddress, RouterSign_addr, isGNS } =
    useGetELPData(elpName);
  const RouterSignContract = useRouterSignContract(RouterSign_addr);
  const { data: tokenAllowance } = useSWR(
    [
      active,
      chainId,
      collateralTokenAddress,
      "allowance",
      account,
      RouterSign_addr,
    ],
    {
      fetcher: fetcher(library, Token),
    }
  );
  // const { data: minExecutionFee } = useSWR([active, chainId, positionRouterAddress, "minExecutionFee"], {
  //   fetcher: fetcher(library, PositionRouter),
  // });

  const isDeposit = option === DEPOSIT;
  const isWithdrawal = option === WITHDRAW;

  const needPositionRouterApproval = !positionRouterApproved;

  let collateralToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;
  let needApprove;
  const [needApproval, setNeedApproval] = useState(true);

  let convertedAmount;
  let convertedAmountFormatted;

  let nextLeverage;
  let nextLeverageExcludingPnl;
  let liquidationPrice;
  let nextLiquidationPrice;
  let nextCollateral;

  let title;
  let collateralDelta;
  if (position) {
    title = `Edit ${position.isLong ? "Long" : "Short"} ${
      position.indexTokenInfo.symbol
    }`;
    collateralToken = position.collateralTokenInfo;
    // liquidationPrice = getLiquidationPrice(position);
    liquidationPrice = getLiquidationPrice2(position);

    if (isDeposit) {
      fromAmount = parseValue(fromValue, collateralToken.decimals);
      maxAmount = collateralToken ? collateralToken.balance : bigNumberify(0);
      maxAmountFormatted = formatAmount(
        maxAmount,
        collateralToken.decimals,
        4,
        true
      );
      maxAmountFormattedFree = formatAmountFree(
        maxAmount,
        collateralToken.decimals,
        8
      );
      if (fromAmount) {
        convertedAmount = getUsd(
          fromAmount,
          position.collateralToken,
          false,
          infoTokens
        );
        convertedAmountFormatted = formatAmount(
          convertedAmount,
          USD_DECIMALS,
          2
        );
      }
    } else {
      fromAmount = parseValue(fromValue, USD_DECIMALS);
      maxAmount = position.collateral;
      maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
      maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);
      if (fromAmount) {
        convertedAmount = fromAmount
          .mul(expandDecimals(1, collateralToken.decimals))
          .div(collateralToken.maxPrice);
        convertedAmountFormatted = formatAmount(
          convertedAmount,
          collateralToken.decimals,
          4,
          true
        );
      }
    }
    needApprove =
      isDeposit &&
      tokenAllowance &&
      fromAmount &&
      fromAmount.gt(tokenAllowance);
    if (fromAmount) {
      collateralDelta = isDeposit ? convertedAmount : fromAmount;
      if (position.isLong) {
        collateralDelta = collateralDelta
          .mul(BASIS_POINTS_DIVISOR - DEPOSIT_FEE)
          .div(BASIS_POINTS_DIVISOR);
      }
      nextLeverage = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: savedIsPnlInLeverage,
      });
      nextLeverageExcludingPnl = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: false,
      });

      // nextLiquidationPrice = getLiquidationPrice({
      //   isLong: position.isLong,
      //   size: position.size,
      //   collateral: position.collateral,
      //   averagePrice: position.averagePrice,
      //   entryFundingRate: position.entryFundingRate,
      //   cumulativeFundingRate: position.cumulativeFundingRate,
      //   collateralDelta,
      //   increaseCollateral: isDeposit,
      // });
      // console.log(collateralDelta, ethers.utils.formatUnits(collateralDelta, 30), "collateralDelta");
      nextLiquidationPrice = getLiquidationPrice2(position, collateralDelta);
      nextCollateral = isDeposit
        ? position.collateral.add(collateralDelta)
        : position.collateral.sub(collateralDelta);
    }
  }
  useEffect(() => {
    setNeedApproval(needApprove);
  }, [needApprove]);

  const getError = () => {
    if (!fromAmount) {
      return "Enter an amount";
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return "Enter an amount";
    }

    if (!isDeposit && fromAmount) {
      if (fromAmount.gte(position.collateral)) {
        return "Min order: 10 USD";
      }
      if (
        position.collateral.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))
      ) {
        return "Min order: 10 USD";
      }
    }

    if (!isDeposit && fromAmount && nextLiquidationPrice) {
      if (position.isLong && position.markPrice.lt(nextLiquidationPrice)) {
        return "Invalid liq. price";
      }
      if (!position.isLong && position.markPrice.gt(nextLiquidationPrice)) {
        return "Invalid liq. price";
      }
    }

    if (
      nextLeverageExcludingPnl &&
      nextLeverageExcludingPnl.lt(1.1 * BASIS_POINTS_DIVISOR)
    ) {
      return "Min leverage: 1.1x";
    }

    if (
      nextLeverageExcludingPnl &&
      nextLeverageExcludingPnl.gt(30.5 * BASIS_POINTS_DIVISOR)
    ) {
      return "Max leverage: 30x";
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSwapping) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isSwapping) {
      if (isDeposit) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="">Depositing</div>
            <img src={PendingIcon} height={20} className="ml-12" />
          </Box>
        );
      }
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Withdrawing</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }

    if (isApproving) {
      return `Approving ${position.collateralTokenInfo.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${position.collateralTokenInfo.symbol}`;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return "Enabling Leverage";
    }

    if (isPositionRouterApproving) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Enabling Leverage</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }

    if (needPositionRouterApproval) {
      return "Enable Leverage";
    }

    if (isDeposit) {
      return "Deposit";
    }

    return "Withdraw";
  };

  const resetForm = () => {
    setFromValue("");
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const depositCollateral = async () => {
    setIsSwapping(true);
    const tokenAddress0 =
      collateralTokenAddress === AddressZero
        ? nativeTokenAddress
        : collateralTokenAddress;
    const path = [tokenAddress0];
    const indexTokenAddress =
      position.indexToken === AddressZero
        ? nativeTokenAddress
        : position.indexToken;

    const priceBasisPoints = position.isLong ? 11000 : 9000;
    const priceLimit = position.indexTokenInfo.maxPrice
      .mul(priceBasisPoints)
      .div(10000);

    let signeData;
    try {
      let signaturegnData;
      if (isGNS) {
        signaturegnData = await getPositionSignaturegns(
          chainId,
          indexTokenAddress
        );
        signaturegnData = signaturegnData;
      } else {
        signaturegnData = await getPositionSignature(chainId);
      }
      signeData = signaturegnData.data;
    } catch (error) {
      console.log(error);
    }

    if (
      !isGNS &&
      (signeData.code != 200 ||
        !signeData.data._priceBits ||
        !signeData.data._priceTimestamp ||
        !signeData.data._updater ||
        !signeData.data._updaterSignedMsg)
    ) {
      toastError("System error!");
      setIsSwapping(false);
      return;
    }
    if (
      isGNS &&
      (signeData.code != 200 ||
        !signeData.data._priceTimestamp ||
        !signeData.data._updater ||
        !signeData.data._updaterSignedMsg ||
        !signeData.data._paras)
    ) {
      toastError("System error!");
      setIsSwapping(false);
      return;
    }

    const referralCode = ethers.constants.HashZero;
    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      fromAmount, // _amountIn
      0, // _sizeDelta
      position.isLong, // _isLong
      priceLimit,
      signeData?.data?._priceBits, //_priceBits
      signeData?.data?._priceTimestamp, //_priceTimestamp
      signeData?.data?._updater, //_updater
      signeData?.data?._updaterSignedMsg, //_updaterSignedMsg
    ];
    let params2 = [
      path, // _path
      indexTokenAddress, // _indexToken
      fromAmount, // _amountIn
      0, // _sizeDelta
      position.isLong, // _isLong
      priceLimit,
      signeData?.data?._paras, //_paras
      signeData?.data?._priceTimestamp, //_priceTimestamp
      signeData?.data?._updater, //_updater
      signeData?.data?._updaterSignedMsg, //_updaterSignedMsg
    ];
    let method = "increasePosition";
    let method2 = "increasePositionAndUpdate";

    let value = minExecutionFee;
    if (collateralTokenAddress === AddressZero) {
      method = "increasePositionETH";
      method2 = "increasePositionETHAndUpdate";
      value = fromAmount.add(minExecutionFee);
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _sizeDelta
        position.isLong, // _isLong
        priceLimit, // _acceptablePrice
        signeData?.data?._priceBits, //_priceBits
        signeData?.data?._priceTimestamp, //_priceTimestamp
        signeData?.data?._updater, //_updater
        signeData?.data?._updaterSignedMsg, //_updaterSignedMsg
      ];
      params2 = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _sizeDelta
        position.isLong, // _isLong
        priceLimit, // _acceptablePrice
        signeData?.data?._paras, //_paras
        signeData?.data?._priceTimestamp, //_priceTimestamp
        signeData?.data?._updater, //_updater
        signeData?.data?._updaterSignedMsg, //_updaterSignedMsg
      ];
    }

    if (
      shouldRaiseGasError(
        getTokenInfo(infoTokens, collateralTokenAddress),
        fromAmount
      )
    ) {
      setIsSwapping(false);
      // helperToast.error(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
      toastError(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
      return;
    }

    // const contract = new ethers.Contract(
    //   positionRouterAddress,
    //   PositionRouter.abi,
    //   library.getSigner()
    // );
    let allmethod = isGNS ? method2 : method;
    let allparams = isGNS ? params2 : params;
    let contracts = RouterSignContract;
    debugger;
    callContract(chainId, contracts, allmethod, allparams, {
      value,
      sentMsg: "Deposit submitted.",
      successMsg: `Requested deposit of ${formatAmount(
        fromAmount,
        position.collateralTokenInfo.decimals,
        4
      )} ${position.collateralTokenInfo.symbol} into ${
        position.indexTokenInfo.symbol
      } ${position.isLong ? "Long" : "Short"}.`,
      failMsg: "Deposit failed.",
      setPendingTxns,
    })
      .then(async (res) => {
        upDataBlock(res);
        setFromValue("");
        setIsVisible(false);

        // pendingPositions[position.key] = {
        //   updatedAt: Date.now(),
        //   pendingChanges: {
        //     collateralSnapshot: position.collateral,
        //     expectingCollateralChange: true,
        //   },
        // };

        // setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const withdrawCollateral = async () => {
    setIsSwapping(true);
    const tokenAddress0 =
      collateralTokenAddress === AddressZero
        ? nativeTokenAddress
        : collateralTokenAddress;
    const indexTokenAddress =
      position.indexToken === AddressZero
        ? nativeTokenAddress
        : position.indexToken;
    const priceBasisPoints = position.isLong ? 9000 : 11000;
    const priceLimit = position.indexTokenInfo.maxPrice
      .mul(priceBasisPoints)
      .div(10000);

    const withdrawETH =
      collateralTokenAddress === AddressZero ||
      collateralTokenAddress === nativeTokenAddress;
    // const params = [
    //   [tokenAddress0], // _path
    //   indexTokenAddress, // _indexToken
    //   fromAmount, // _collateralDelta
    //   0, // _sizeDelta
    //   position.isLong, // _isLong
    //   account, // _receiver
    //   priceLimit, // _acceptablePrice
    //   0, // _minOut
    //   minExecutionFee, // _executionFee
    //   withdrawETH, // _withdrawETH
    // ];

    let signeData;
    try {
      let signaturegnData;
      if (isGNS) {
        signaturegnData = await getPositionSignaturegns(
          chainId,
          indexTokenAddress
        );
        signaturegnData = signaturegnData;
      } else {
        signaturegnData = await getPositionSignature(chainId);
      }
      signeData = signaturegnData.data;
    } catch (error) {
      console.log(error);
    }

    if (
      !isGNS &&
      (signeData.code != 200 ||
        !signeData.data._priceBits ||
        !signeData.data._priceTimestamp ||
        !signeData.data._updater ||
        !signeData.data._updaterSignedMsg)
    ) {
      toastError("System error!");
      setIsSwapping(false);
      return;
    }
    if (
      isGNS &&
      (signeData.code != 200 ||
        !signeData.data._priceTimestamp ||
        !signeData.data._updater ||
        !signeData.data._updaterSignedMsg ||
        !signeData.data._paras)
    ) {
      toastError("System error!");
      setIsSwapping(false);
      return;
    }
    let params = [
      tokenAddress0, //_collateralToken
      indexTokenAddress, // _indexToken
      fromAmount.toString(), // _collateralDelta
      0, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit.toString(), // _acceptablePrice
      signeData?.data?._priceBits, //_priceBits
      signeData?.data?._priceTimestamp, //_priceTimestamp
      signeData?.data?._updater, //_updater
      signeData?.data?._updaterSignedMsg, //_updaterSignedMsg
    ];

    let params2 = [
      tokenAddress0, //_collateralToken
      indexTokenAddress, // _indexToken
      fromAmount.toString(), // _collateralDelta
      0, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit.toString(), // _acceptablePrice
      signeData?.data?._paras, //_paras
      signeData?.data?._priceTimestamp, //_priceTimestamp
      signeData?.data?._updater, //_updater
      signeData?.data?._updaterSignedMsg, //_updaterSignedMsg
    ];

    const method = "decreasePosition";
    const method2 = "decreasePositionAndUpdate";
    let allparams = isGNS ? params2 : params;
    let allmethod = isGNS ? method2 : method;
    let contracts = RouterSignContract;
    callContract(chainId, contracts, allmethod, allparams, {
      value: minExecutionFee,
      sentMsg: "Withdrawal submitted.",
      successMsg: `Requested withdrawal of ${formatAmount(
        fromAmount,
        USD_DECIMALS,
        2
      )} USD from ${position.indexTokenInfo.symbol} ${
        position.isLong ? "Long" : "Short"
      }.`,
      failMsg: "Withdrawal failed.",
      setPendingTxns,
    })
      .then(async (res) => {
        upDataBlock(res);
        setFromValue("");
        setIsVisible(false);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        };
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const onClickPrimary = useCallback(async () => {
    if (needApproval) {
      await approveTokens({
        setIsApproving,
        library,
        tokenAddress: collateralTokenAddress,
        spender: RouterSign_addr,
        chainId: chainId,
        onApproveSubmitted: () => {
          setIsWaitingForApproval(true);
        },
        infoTokens,
        getTokenInfo,
        pendingTxns,
        setPendingTxns,
      });
      setIsWaitingForApproval(false);
      setNeedApproval(false);
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: isDeposit ? "Enable deposit sent." : "Enable withdraw sent.",
        failMsg: isDeposit
          ? "Enable deposit failed."
          : "Enable withdraw failed.",
      });
      return;
    }

    if (isDeposit) {
      depositCollateral();
      return;
    }

    withdrawCollateral();
  }, [needApproval, needPositionRouterApproval, infoTokens, isDeposit]);

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  const editToken = useMemo(() => {
    if (isDeposit) {
      return position?.collateralTokenInfo;
    }

    return {
      symbol: "USD",
      imageUrl: USD,
    };
  }, [isDeposit, position]);
  const usdText = useMemo(() => {
    if (convertedAmountFormatted === undefined) return "0";
    if (isDeposit) {
      return `$${convertedAmountFormatted}`;
    }

    return `${convertedAmountFormatted} ${position?.collateralTokenInfo.symbol}`;
  }, [convertedAmountFormatted, position]);
  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <div className="dialogBg w-812">
        <div className="dialogContent">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="dialogHeader"
          >
            <div className="">
              <div className="font-24 font-weight-b ">{title}</div>
            </div>
            <img src={Close} onClick={handleClose} />
          </Box>
          <div className="dialogInfo sellers">
            <Box
              display={"flex"}
              className="h5sellers"
              justifyContent="flex-start"
              flex={1}
              pb={"32px"}
            >
              <Box flex={"1"}>
                <Tab
                  options={EDIT_OPTIONS}
                  optionLabels={{ Deposit: "Deposit", Withdraw: "Withdraw" }}
                  className="Exchange-swap-order-type-tabs"
                  type="inline"
                  option={option}
                  onChange={(e) => {
                    setOption(e);
                  }}
                />

                <InputSelect
                  className="mt-16"
                  leftLable={isDeposit ? "Deposit" : "Withdraw"}
                  value={fromValue}
                  balance={
                    isDeposit
                      ? `balance : ${maxAmountFormatted}`
                      : `collateral : $${maxAmountFormatted}`
                  }
                  collateral
                  totalBalance={maxAmountFormattedFree}
                  onUserInput={setFromValue}
                  token={editToken}
                  usdText={usdText}
                  showMax={true}
                />

                <Box
                  className="rg_box"
                  display={"flex"}
                  flexDirection="column"
                  gridGap={"12px"}
                  pt={"16px"}
                  pl={"18px"}
                  pr={"14px"}
                >
                  <div className="p_dtail">
                    <div className="dtail_lt font-weight-6">Leverage</div>
                    <div className="dtail_rt font-weight-6">
                      {!nextLeverage && (
                        <div>
                          {formatAmount(position?.leverage, 4, 2, true)}x
                        </div>
                      )}
                      {nextLeverage && (
                        <div className="g_flex">
                          <div className="f_size">
                            {formatAmount(position?.leverage, 4, 2, true)}x
                            <BsArrowRight />
                          </div>
                          {formatAmount(nextLeverage, 4, 2, true)}x
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              </Box>

              <Box flex={"1"} className={"sellrg_box"}>
                <div className="boders_sell">
                  <div className="p_list">
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Size</div>
                      <div className="dtail_rt font-weight-6">
                        {formatAmount(position?.size, USD_DECIMALS, 2, true)}{" "}
                        USD
                      </div>
                    </div>
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Collateral</div>
                      <div className="dtail_rt font-weight-6">
                        {!nextCollateral && (
                          <div>
                            $
                            {formatAmount(
                              position?.collateral,
                              USD_DECIMALS,
                              2,
                              true
                            )}
                          </div>
                        )}
                        {nextCollateral && (
                          <div className="g_flex">
                            <div className="f_size">
                              $
                              {formatAmount(
                                position?.collateral,
                                USD_DECIMALS,
                                2,
                                true
                              )}
                              <BsArrowRight />
                            </div>
                            $
                            {formatAmount(
                              nextCollateral,
                              USD_DECIMALS,
                              2,
                              true
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Mark Price</div>
                      <div className="dtail_rt font-weight-6">
                        $
                        {formatAmount(
                          position?.markPrice,
                          USD_DECIMALS,
                          2,
                          true
                        )}
                      </div>
                    </div>
                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">Liq. Price</div>
                      <div className="dtail_rt font-weight-6">
                        {!nextLiquidationPrice && (
                          <div>
                            {!fromAmount &&
                              `$${formatAmount(
                                liquidationPrice,
                                USD_DECIMALS,
                                2,
                                true
                              )}`}
                            {fromAmount && "-"}
                          </div>
                        )}
                        {nextLiquidationPrice && (
                          <div className="g_flex">
                            <div className="f_size">
                              $
                              {formatAmount(
                                liquidationPrice,
                                USD_DECIMALS,
                                2,
                                true
                              )}
                              <BsArrowRight />
                            </div>
                            $
                            {formatAmount(
                              nextLiquidationPrice,
                              USD_DECIMALS,
                              2,
                              true
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="sell_line"></div>

                    <div className="p_dtail">
                      <div className="dtail_lt font-weight-6">
                        Execution Fee
                      </div>
                      <div className="dtail_rt font-weight-6">
                        <Tooltips
                          position="right-bottom"
                          handleClassName=""
                          renderContent={
                            <Column
                              gap="12px"
                              lineHeight="20px"
                              className="font-14"
                            >
                              <TooltipItem
                                label="Network fee"
                                val={`${formatAmountFree(
                                  minExecutionFee,
                                  18,
                                  5
                                )} ${nativeTokenSymbol} (${formatAmount(
                                  minExecutionFeeUSD,
                                  USD_DECIMALS,
                                  2
                                )}
                                )`}
                              />
                              <div className="">
                                This is the network cost required to execute the{" "}
                                {isDeposit ? "deposit" : "withdrawal"}.
                              </div>
                            </Column>
                          }
                        >
                          <span className="toolBottom">
                            {formatAmountFree(minExecutionFee, 18, 5)}{" "}
                            {nativeTokenSymbol}
                          </span>
                        </Tooltips>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </div>
          <div className="">
            <ConfirmBtn onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </ConfirmBtn>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
