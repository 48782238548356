import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useGetELPData = (elpName?: string) => {
  const ELPMAP = useSelector((state: any) => {
    return state.app.ELPMAP || {};
  });
  return useMemo(() => {
    if (!elpName || !ELPMAP[elpName]) return {};
    const {
      routerSign_adddress,
      router_address,
      positionRouter_address,
      orderBook_address,
    } = ELPMAP[elpName];
    return {
      routerAddress: router_address,
      RouterSign_addr: routerSign_adddress,
      positionRouterAddress: positionRouter_address,
      orderBookAddress: orderBook_address,
      ...ELPMAP[elpName],
    };
  }, [ELPMAP, elpName]);
};
