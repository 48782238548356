import {
  BASIS_POINTS_DIVISOR,
  bigNumberify,
  getDeltaStr,
  getLeverage,
  getPositionKey,
  MARGIN_FEE_BASIS_POINTS,
  PLACEHOLDER_ACCOUNT,
} from "src/helpers/Helpers";

import {
  usePositionReaderContract,
  useReaderContract,
  useRouterContract,
} from "src/hooks/useContract";
import { useWeb3Context } from "src/hooks/web3Context";
import {
  useMultipleContractSingleData,
  useSingleCallResult,
} from "src/lib/hooks/multicall";
import { addresses as ADDRESS } from "src/configs/constants";
import { useMemo } from "react";
import { AddressZero } from "src/constants/address";
import { toBN } from "src/utils/bn";
import { BigNumber } from "@ethersproject/bignumber";
import { useAppSelector } from "src/state/hooks";
import { compareAddress } from "src/utils/address";
import { ELP_LIST } from "src/constants/tokens";
import { abi as VaultUtilsABI } from "src/abis/VaultUtils.json";
import { abi as PositionReaderABI } from "src/abis/PositionReader.json";
import { Interface } from "@ethersproject/abi";
import { useMultipleContractMultipleData } from "src/lib/hooks/useMultipleContractMultipleData";
import { useGetELPData } from "src/hooks/useGetELPData";
// type PositionData = {
//   account: string;
//   collateralToken: string;
//   indexToken: string;
//   size: BigNumber;
//   collateral: BigNumber;
//   averagePrice: BigNumber;
//   reserveAmount: BigNumber;
//   lastUpdateTime: BigNumber;
//   aveIncreaseTime: BigNumber;
//   entryFundingRateSec: BigNumber;
//   entryPremiumRateSec: BigNumber;
//   realisedPnl: BigNumber;
//   stopLossRatio: BigNumber;
//   takeProfitRatio: BigNumber;
//   isLong: boolean;
//   key: string;
//   delta: BigNumber;
//   hasProfit: boolean;
//   accPremiumFee: BigNumber;
//   accFundingFee: BigNumber;
//   accPositionFee: BigNumber;
//   accCollateral: BigNumber;

//   pendingPremiumFee: BigNumber;
//   pendingPositionFee: BigNumber;
//   pendingFundingFee: BigNumber;

//   indexTokenMinPrice: BigNumber;
//   indexTokenMaxPrice: BigNumber;
// };

// type PositionProps = {
//   netValue: BigNumber;
//   fundingFee: BigNumber;
//   positionFee: BigNumber;
//   totalFees: BigNumber;
// };

// type ALLPositionType = PositionProps;

const VAULTUTIL_INTERFACE = new Interface(VaultUtilsABI);
const POSITIONREADER_INTERFACE = new Interface(PositionReaderABI);
export function useGetpositions(infoTokens: any) {
  const { chainID, address: account } = useWeb3Context();
  const WETH = ADDRESS[chainID].NATIVE_TOKEN;
  const positionReaderContract = usePositionReaderContract();
  const ALLList = ELP_LIST[chainID];
  const accounts = useMemo(
    () => (account ? account : PLACEHOLDER_ACCOUNT),
    [account]
  );
  const { getPositionData, vaultUtils } = useMemo(() => {
    return ALLList.reduce((pre: any, curr) => {
      if (pre.getPositionData) {
        pre.getPositionData.addr.push(positionReaderContract?.address);
        pre.getPositionData.data.push([[curr.vault_address, accounts]]);
        pre.vaultUtils.addr.push(curr.vaultUtil_addr);
      } else {
        pre = {
          getPositionData: {
            addr: [positionReaderContract?.address],
            data: [[[curr.vault_address, accounts]]],
          },
          vaultUtils: {
            addr: [curr.vaultUtil_addr],
          },
        };
      }
      return pre;
    }, {});
  }, [ALLList, accounts, positionReaderContract?.address]);
  const positionDatas = useMultipleContractMultipleData(
    getPositionData?.addr ?? [undefined],
    POSITIONREADER_INTERFACE,
    "getUserPositions",
    getPositionData?.data ?? [undefined]
  );
  const VaultUtilDatas = useMultipleContractSingleData(
    vaultUtils?.addr ?? [undefined],
    VAULTUTIL_INTERFACE,
    "taxDuration"
  );
  const filterDatas = useMemo(() => {
    return positionDatas.reduce((pre: any, curr, index) => {
      const { result } = curr[0];
      const { result: Util } = VaultUtilDatas[index];
      if (!result || !result[0] || !Util) return;
      const filterData = result[0]
        .filter((x: any) => x.account != AddressZero)
        .map((x: any) => {
          return {
            ...x,
            taxDuration: Util[0],
          };
        });
      pre.push(...filterData);
      return pre;
    }, []);
  }, [VaultUtilDatas, positionDatas]);
  return useMemo(() => {
    if (!filterDatas || filterDatas.length == 0 || !infoTokens)
      return { positions: [], positionsMap: {} };
    const includeDelta = window.localStorage.getItem(
      `[${chainID},"Exchange-swap-is-pnl-in-leverage"]`
    );
    const showPnlAfterFees = window.localStorage.getItem(
      `[${chainID},"Exchange-swap-show-pnl-after-fees"]`
    );
    const data = filterDatas.reduce(
      (pre: any, item: any) => {
        const taxDuration = item.taxDuration;
        const position: any = {
          ...item,
        };

        position.pendingPremiumFee = item.isLong
          ? item.pendingPremiumFee
          : item.pendingPremiumFee;
        position.closingFee = item.size
          .mul(MARGIN_FEE_BASIS_POINTS)
          .div(BASIS_POINTS_DIVISOR);
        position.fundingFee = item.pendingFundingFee
          ? item.pendingFundingFee
          : bigNumberify(0);
        position.collateralAfterFee = item.collateral.sub(position.fundingFee);
        let indexToken = infoTokens[item.indexToken.toLocaleLowerCase()];
        position.indexTokenInfo = indexToken;
        position.collateralTokenInfo =
          infoTokens[item.collateralToken.toLocaleLowerCase()];
        position.collateralToken = item.collateralToken;

        let key;
        if (position.isLong) {
          key = getPositionKey(
            account,
            position.collateralToken,
            position.collateralToken,
            position.isLong
          );
        } else {
          key = getPositionKey(
            account,
            "",
            position.indexToken,
            position.isLong
          );
        }
        if (position.indexTokenInfo?.isGNS) {
          key = getPositionKey(
            account,
            item.collateralToken,
            position.indexToken,
            position.isLong
          );
        }
        position.key = key;
        // NATIVE_TOKEN
        if (compareAddress(item.indexToken, WETH)) {
          indexToken = infoTokens[AddressZero];
          position.indexTokenInfo = infoTokens[AddressZero];
        }
        const markPrice = item.isLong
          ? item.indexTokenMinPrice
          : item.indexTokenMaxPrice;
        const markPrice1 = item.isLong
          ? indexToken?.minPrice
          : indexToken?.maxPrice;
        position.markPrice = markPrice1 ?? markPrice;
        position.pendingDelta = item.delta;
        if (item.collateral.gt(0)) {
          position.hasLowCollateral =
            position.collateralAfterFee.lt(0) ||
            item.size.div(position.collateralAfterFee.abs()).gt(50);

          if (item.averagePrice.gt(0) && position.markPrice) {
            const priceDelta = item.averagePrice.gt(position.markPrice)
              ? item.averagePrice.sub(position.markPrice)
              : position.markPrice.sub(item.averagePrice);
            position.pendingDelta = item.size
              .mul(priceDelta)
              .div(item.averagePrice);

            position.delta = position.pendingDelta;

            if (item.isLong) {
              position.hasProfit = position.markPrice.gte(item.averagePrice);
            } else {
              position.hasProfit = position.markPrice.lte(item.averagePrice);
            }
          }

          position.deltaPercentage = position.delta
            .mul(BASIS_POINTS_DIVISOR)
            .div(item.collateral);

          const { deltaStr, deltaPercentageStr } = getDeltaStr({
            delta: position.delta,
            deltaPercentage: position.deltaPercentage,
            hasProfit: position.hasProfit,
          });

          position.deltaStr = deltaStr;
          position.deltaPercentageStr = deltaPercentageStr;
          position.deltaBeforeFeesStr = deltaStr;

          const currentTime = Math.floor(new Date().getTime() / 1000);
          const levelTime = BigNumber.from(currentTime).sub(
            item.aveIncreaseTime
          );
          position.termTax = bigNumberify(0);

          if (taxDuration) {
            if (levelTime.lt(taxDuration)) {
              if (position.hasProfit) {
                const shareTax = toBN(levelTime)
                  .div(toBN(taxDuration))
                  .times(toBN(position.delta));
                const termTax = toBN(position.delta).minus(shareTax);
                position.termTax = bigNumberify(termTax.toFixed(0));
              }
            }
          }

          position.totalFees = item.pendingPositionFee
            .add(item.pendingFundingFee)
            .add(item.pendingPremiumFee)
            .add(position.termTax);

          let hasProfitAfterFees;
          let pendingDeltaAfterFees;
          if (position.hasProfit) {
            if (position.delta.gt(position.totalFees)) {
              hasProfitAfterFees = true;
              pendingDeltaAfterFees = position.delta.sub(position.totalFees);
            } else {
              hasProfitAfterFees = false;
              pendingDeltaAfterFees = position.totalFees.sub(position.delta);
            }
          } else {
            if (position.totalFees.gt(0)) {
              hasProfitAfterFees = false;
              pendingDeltaAfterFees = position.delta.add(position.totalFees);
            } else {
              const val = position.totalFees.abs();
              pendingDeltaAfterFees = position.totalFees
                .abs()
                .sub(position.delta);
              if (position.delta.gt(val)) {
                hasProfitAfterFees = false;
              } else {
                hasProfitAfterFees = true;
              }
            }
          }

          position.hasProfitAfterFees = hasProfitAfterFees;
          position.pendingDeltaAfterFees = pendingDeltaAfterFees;
          position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
            .mul(BASIS_POINTS_DIVISOR)
            .div(position.collateral);

          const {
            deltaStr: deltaAfterFeesStr,
            deltaPercentageStr: deltaAfterFeesPercentageStr,
          } = getDeltaStr({
            delta: position.pendingDeltaAfterFees,
            deltaPercentage: position.deltaPercentageAfterFees,
            hasProfit: hasProfitAfterFees,
          });

          position.deltaAfterFeesStr = deltaAfterFeesStr;
          position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

          if (showPnlAfterFees === "true") {
            position.deltaStr = position.deltaAfterFeesStr;
            position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
          }

          let netValue = position.hasProfit
            ? position.collateral.add(position.pendingDelta)
            : position.collateral.sub(position.pendingDelta);
          netValue = netValue
            .sub(item.pendingPremiumFee)
            .sub(position.closingFee)
            .sub(item.pendingFundingFee);
          position.netValue = netValue;
        }

        // position.leverage = item.size.div(item.collateral);
        position.leverage = getLeverage({
          size: item.size,
          collateral: item.collateral,
          entryFundingRate: position.entryFundingRate,
          cumulativeFundingRate: position.cumulativeFundingRate,
          hasProfit: position.hasProfit,
          delta: position.delta,
          includeDelta: includeDelta === "true",
        } as any);
        pre.positions.push(position);
        pre.positionsMap[key] = position;
        return pre;
      },
      { positions: [], positionsMap: {} }
    );
    return data;
  }, [filterDatas, infoTokens, chainID, account, WETH]);
}

export function useGetTokenBalances() {
  const readerContract = useReaderContract();
  const { address: account } = useWeb3Context();

  const allToken = useAppSelector((state: any) => {
    return state.app.allToken || [];
  });

  const parmas = useMemo(() => {
    if (!allToken) return;
    return allToken
      .filter((x: any) => !x.isGNS)
      .map((item: any) => item.address);
  }, [allToken]);

  const { result } = useSingleCallResult(readerContract, "getTokenBalances", [
    account ? account : PLACEHOLDER_ACCOUNT,
    parmas,
  ]);
  return useMemo(() => {
    if (!result) return;
    return result[0];
  }, [result]);
}

export function useQueryApprovedPlugins(elpName?: string) {
  const { routerAddress, orderBookAddress, positionRouterAddress } =
    useGetELPData(elpName);
  const { address } = useWeb3Context();
  const RouterContract = useRouterContract(routerAddress);

  const { result: routerApporve } = useSingleCallResult(
    RouterContract,
    "approvedPlugins",
    [
      address ? address : PLACEHOLDER_ACCOUNT,
      positionRouterAddress ?? undefined,
    ]
  );
  const { result: orderBookApporve } = useSingleCallResult(
    RouterContract,
    "approvedPlugins",
    [address ? address : PLACEHOLDER_ACCOUNT, orderBookAddress ?? undefined]
  );

  return {
    orderBookApproved1: orderBookApporve?.[0],
    positionRouterApproved: routerApporve?.[0],
  };
}
