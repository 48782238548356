import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const CloseBtn = styled(Button)({
  height: "40px",
  lineHeight: "40px",
  maxHeight: "40px",
  width: "100px",
  color: "#F89542",
  fontSize: 14,
  fontFamily: "Poppins-Bold",
  textTransform: "uppercase",
  background: "rgba(255, 255, 255, 0.08)",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "#F89542",
    color: "#ffffff",
    ".closeIcon": {
      path: {
        stroke: "#ffffff",
      },
    },
  },
});
