import { addresses } from "./configs/constants";

export function getContractAddress(chainId, name) {
  if (!addresses[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  if (!addresses[chainId][name]) {
    // throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
    console.error(`Unknown contract "${name}" for chainId ${chainId}`)
    return ""
  }
  return addresses[chainId][name];
}
