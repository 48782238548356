import moment from "moment";
import { useMemo } from "react";

import { addresses as ADDRESS } from "../configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { BN, toFromBN } from "src/utils/bn";
import { useGetEdePrice } from "./getPrice_ede";
import {
  useAEDEContract,
  useEDEContract,
  useEdeDistributionContract,
  useEusdContract,
  useEusdDistributionContract,
  useInfoHelperContract,
  useOLDVeStakerContract,
  useOldVeStakerContract,
  useRewardRouterContract,
  useVeStakerContract,
} from "./useContract";
import { useWeb3Context } from "./web3Context";
import { ethers } from "ethers";
import { getTokens } from "src/configs/Tokens";
import { compareAddress } from "src/utils/address";

export const useEusdAccount = () => {
  const { address: account } = useWeb3Context();
  const eusdContract = useEusdContract();

  const { result } = useSingleCallResult(eusdContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEUSDTotalSupply = () => {
  const eusdContract = useEusdContract();

  const { result } = useSingleCallResult(eusdContract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEDETotalSupply = () => {
  const EDEContract = useEDEContract();

  const { result } = useSingleCallResult(EDEContract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEDEAccount = () => {
  const { address } = useWeb3Context();
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useVeStakerLocked = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useOldVeStakerLocked = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useOldVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useGEDEAccount = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useOldGEDEAccount = () => {
  const { address: account } = useWeb3Context();
  const gedeContract = useOLDVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useGEDETotalSupply = () => {
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useOldGEDETotalSupply = () => {
  const gedeContract = useOldVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEDEVeStakerTotalSupply = () => {
  const gedeContract = useVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalEDESupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};
export const useOldEDEVeStakerTotalSupply = () => {
  const gedeContract = useOldVeStakerContract();
  const { result } = useSingleCallResult(gedeContract, "totalEDESupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useAllEDEVeStakerTotalSupply = () => {
  const one = useEDEVeStakerTotalSupply();
  const two = useOldEDEVeStakerTotalSupply();
  return useMemo(() => {
    if (!one) return;
    else if (one && !two) return one;
    else if (one && two) return one.plus(two);
  }, [one, two]);
};

export const useEDEStakedAccount = () => {
  const { address: account } = useWeb3Context();
  const EDEContract = useEDEContract();
  const { result } = useSingleCallResult(EDEContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useAEDEAccount = () => {
  const { address: account } = useWeb3Context();
  const aedeContract = useAEDEContract();
  const { result } = useSingleCallResult(aedeContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEdeYieldDistributor = () => {
  const EdeYieldDistributorContract = useEdeDistributionContract();
  const { result } = useSingleCallResult(
    EdeYieldDistributorContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useEUSDYieldDistributor = () => {
  const EdeYieldDistributorContract = useEusdDistributionContract();
  const { result } = useSingleCallResult(
    EdeYieldDistributorContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};
export const useEDETotalBalance = () => {
  const gedeBalance = useGEDEAccount();
  const edeBalance = useEDEAccount();
  const edeStaked = useEDEStakedAccount();
  const eusdBalance = useEusdAccount();
  const balance_aEDE = useAEDEAccount();

  return useMemo(() => {
    if (
      !gedeBalance ||
      !edeBalance ||
      !edeStaked ||
      !eusdBalance ||
      !balance_aEDE
    )
      return {
        gedeBalance,
        edeBalance,
        edeStaked,
        eusdBalance,
        balance_aEDE,
      };
  }, [balance_aEDE, edeBalance, edeStaked, eusdBalance, gedeBalance]);
};
export const useQueryEarnApr = () => {
  const gedeBalance = useGEDEAccount();
  const gedeOldBalance = useOldGEDEAccount();
  const weekRewards_ede = useEdeYieldDistributor();
  const weekRewards_eusd = useEUSDYieldDistributor();
  const totalSupply_gede = useGEDETotalSupply();
  const oldtotalSupply_gede = useOldGEDETotalSupply();

  const edePrice = useGetEdePrice();
  const edeStakedAmount = useVeStakerLocked();
  const oldedeStakedAmount = useOldVeStakerLocked();

  return useMemo(() => {
    if (
      !gedeBalance ||
      !gedeOldBalance ||
      !weekRewards_ede ||
      !weekRewards_eusd ||
      !totalSupply_gede ||
      !edePrice ||
      !edeStakedAmount ||
      !oldtotalSupply_gede ||
      !oldedeStakedAmount
    )
      return;
    let edeApr1 = BN(0);
    let edeApr2 = BN(0);
    let eusdApr1 = BN(0);
    let eusdApr2 = BN(0);
    const allgedeBalance = gedeBalance.plus(gedeOldBalance);
    const alltotalSupply_gede = totalSupply_gede.plus(oldtotalSupply_gede);
    const alledeStaked = edeStakedAmount.amount.plus(oldedeStakedAmount.amount);

    if (alltotalSupply_gede.gt(0)) {
      edeApr1 = weekRewards_ede.div(7).times(365).div(alltotalSupply_gede);
      edeApr2 = weekRewards_ede
        .div(7)
        .times(365)
        .div(alltotalSupply_gede)
        .times(4);
    }

    if (weekRewards_eusd.gt(0) && alltotalSupply_gede.gt(0)) {
      eusdApr1 = weekRewards_eusd
        .div(edePrice)
        .div(7)
        .times(365)
        .div(alltotalSupply_gede);
      eusdApr2 = weekRewards_eusd
        .div(edePrice)
        .div(7)
        .times(365)
        .div(alltotalSupply_gede)
        .times(4);
    }
    const minApr = edeApr1.plus(eusdApr1);
    const maxApr = edeApr2.plus(eusdApr2);
    const edeApr = allgedeBalance.div(alledeStaked).times(edeApr1);
    const eusdApr = allgedeBalance.div(alledeStaked).times(eusdApr1);
    const estApr = edeApr.plus(eusdApr);
    const currtimestamp = moment().valueOf();
    const currtimestampS = (currtimestamp / 1000).toFixed(0);
    const endTime = edeStakedAmount.endTime - Number(currtimestampS);

    return {
      gedeBalance: allgedeBalance,
      oldgedeBalance: gedeOldBalance,
      edeApr1,
      edeApr2,
      eusdApr1,
      eusdApr2,
      minApr,
      maxApr,
      edeApr,
      eusdApr,
      estApr,
      endTime,
      edeStaked: alledeStaked,
      newEdeStaked: edeStakedAmount.amount,
    };
  }, [
    edePrice,
    edeStakedAmount,
    gedeBalance,
    gedeOldBalance,
    oldedeStakedAmount,
    oldtotalSupply_gede,
    totalSupply_gede,
    weekRewards_ede,
    weekRewards_eusd,
  ]);
};

export const useEUSDPoolInfo = () => {
  const RewardRouterContract = useRewardRouterContract();
  const { result } = useSingleCallResult(
    RewardRouterContract,
    "getEUSDPoolInfo"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return {
      totalCollateral: toFromBN(data[0], 30),
      circulatingSupply: toFromBN(data[2]),
      buyFees: BN(data[4].toString()),
      sellFees: BN(data[5].toString()),
    };
  }, [result]);
};

export const useEDEStakeRewards = () => {
  const { address: account } = useWeb3Context();
  const EdeYieldDistributorContract = useEdeDistributionContract();
  const eusdYieldDistributorContract = useEusdDistributionContract();

  const { result: edeReward } = useSingleCallResult(
    EdeYieldDistributorContract,
    "earned",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );

  const { result: eusdReward } = useSingleCallResult(
    eusdYieldDistributorContract,
    "earned",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );

  return useMemo(() => {
    if (!edeReward || !eusdReward) return;
    const rewards_ede = toFromBN(edeReward[0]);
    const rewards_eusd = toFromBN(eusdReward[0]);
    return {
      rewards_ede,
      rewards_eusd,
    };
  }, [edeReward, eusdReward]);
};

export const useQueryInfoHelper = () => {
  const { address: account, chainID } = useWeb3Context();
  const InfoHelperContract = useInfoHelperContract();
  const data = useMemo(() => {
    return [ADDRESS[chainID].ESBT, account ? account : PLACEHOLDER_ACCOUNT];
  }, [account, chainID]);
  const { result } = useSingleCallResult(
    InfoHelperContract,
    "getBasicInfo",
    data,
    {
      gasRequired: 6000000,
    }
  );
  return useMemo(() => {
    if (!result) return;
    const userInfoArr = result[2];
    const rank = userInfoArr[10];
    return rank;
  }, [result]);
};

export const useQeuryEUSDCollateral = () => {
  const RewardRouterContract = useRewardRouterContract();
  const { chainID } = useWeb3Context();
  const tokens = getTokens(chainID);
  const { result } = useSingleCallResult(
    RewardRouterContract,
    "getEUSDCollateralDetail",
    undefined,
    { gasRequired: 3000000 }
  );
  return useMemo(() => {
    if (!result) return;
    const tokenAddressList = result[0];
    const tokenPriceList = result[1];
    const tokenPoolList = result[2];
    const collateralList = [];
    for (let i = 0; i < tokenAddressList.length; i++) {
      const currTokenArr = tokens.filter((token: { address: any }) =>
        compareAddress(tokenAddressList[i], token.address)
      );
      if (currTokenArr[0]) {
        const currToken = currTokenArr[0];
        const tokenAmount = ethers.utils.formatUnits(
          tokenPriceList[i],
          currTokenArr[0].decimals
        );
        const pool = ethers.utils.formatUnits(tokenPoolList[i], 30);
        const price = Number(pool) > 0 ? Number(pool) / Number(tokenAmount) : 0;
        const amount = Number(pool) > 0 ? Number(pool) / price : 0;
        collateralList.push({ amount, price, pool, ...currToken });
      }
    }

    return collateralList;
  }, [result, tokens]);
};

export const useQueryCircLocked = () => {
  const { chainID } = useWeb3Context();
  const EDEContract = useEDEContract();
  const totalSupply_ede = useEDETotalSupply();
  const newtotalSupply_ede = useEDEVeStakerTotalSupply();
  const totalStaked_ede = useAllEDEVeStakerTotalSupply();
  const totalSupply_gede = useGEDETotalSupply();

  const LP_Farm_addr = ADDRESS[chainID].LP_Farm;
  const LP_Marketing_addr = ADDRESS[chainID].LP_Marketing;
  const Contracts_Reserve_addr = ADDRESS[chainID].Contracts_Reserve;
  const TokenVesting_EDE_addr = ADDRESS[chainID].TokenVesting_EDE;
  const TokenVesting_EDE_old_addr = ADDRESS[chainID].TokenVesting_EDE_old;
  const TokenVesting_pEDE_addr = ADDRESS[chainID].TokenVesting_pEDE;
  const TokenVesting_pEDE2_addr = ADDRESS[chainID].TokenVesting_pEDE2;
  const edeDistribution_addr = ADDRESS[chainID].edeDistribution;
  const edeOldDistribution_addr = ADDRESS[chainID].oldedeDistribution;
  const EDEStaking_addr = ADDRESS[chainID].EDEStaking;
  const ido_addr = ADDRESS[chainID].IDO;
  const ede_burn_addr = ADDRESS[chainID].ede_burn;
  const EDE_LP_addr = ADDRESS[chainID].EDE_LP;
  const EDE_DAO_addr = ADDRESS[chainID]?.EDE_DAO;
  const { result: balance_LP_Farm_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [LP_Farm_addr]
  );
  const { result: LP_Marketing_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [LP_Marketing_addr]
  );
  const { result: Contracts_Reserve_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [Contracts_Reserve_addr]
  );
  const { result: TokenVesting_EDE_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [TokenVesting_EDE_addr]
  );
  const { result: TokenVesting_EDE_old_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [TokenVesting_EDE_old_addr]
  );
  const { result: TokenVesting_pEDE_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [TokenVesting_pEDE_addr]
  );
  const { result: TokenVesting_pEDE2_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [TokenVesting_pEDE2_addr]
  );
  const { result: edeDistribution_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [edeDistribution_addr]
  );
  const { result: EDEStaking_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [EDEStaking_addr]
  );
  const { result: oldedeDistribution_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [edeOldDistribution_addr]
  );
  const { result: ido_str } = useSingleCallResult(EDEContract, "balanceOf", [
    ido_addr,
  ]);
  const { result: ede_burn_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [ede_burn_addr]
  );
  const { result: balance_ede_EDE_BUSD_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [EDE_LP_addr]
  );

  const { result: EDE_DAO_balance_str } = useSingleCallResult(
    EDEContract,
    "balanceOf",
    [EDE_DAO_addr]
  );
  const balance_EDE_DAO = useMemo(() => {
    if (!EDE_DAO_balance_str) return;
    return toFromBN(EDE_DAO_balance_str[0]);
  }, [EDE_DAO_balance_str]);
  const balance_LP_Farm = useMemo(() => {
    if (!balance_LP_Farm_str) return;
    return toFromBN(balance_LP_Farm_str[0]);
  }, [balance_LP_Farm_str]);
  const balance_ede_LP_Marketing = useMemo(() => {
    if (!LP_Marketing_str) return;
    return toFromBN(LP_Marketing_str[0]);
  }, [LP_Marketing_str]);

  const balance_ede_Contracts_Reserve = useMemo(() => {
    if (!Contracts_Reserve_str) return;
    return toFromBN(Contracts_Reserve_str[0]);
  }, [Contracts_Reserve_str]);

  const balance_ede_TokenVesting_EDE = useMemo(() => {
    if (!TokenVesting_EDE_str) return;
    return toFromBN(TokenVesting_EDE_str[0]);
  }, [TokenVesting_EDE_str]);

  const balance_ede_TokenVesting_EDE_old = useMemo(() => {
    if (!TokenVesting_EDE_old_str) return;
    return toFromBN(TokenVesting_EDE_old_str[0]);
  }, [TokenVesting_EDE_old_str]);

  const balance_ede_TokenVesting_pEDE = useMemo(() => {
    if (!TokenVesting_pEDE_str) return;
    return toFromBN(TokenVesting_pEDE_str[0]);
  }, [TokenVesting_pEDE_str]);

  const balance_ede_TokenVesting_pEDE2 = useMemo(() => {
    if (!TokenVesting_pEDE2_str) return;
    return toFromBN(TokenVesting_pEDE2_str[0]);
  }, [TokenVesting_pEDE2_str]);

  const balance_ede_EDEStaking = useMemo(() => {
    if (!EDEStaking_str) return;
    return toFromBN(EDEStaking_str[0]);
  }, [EDEStaking_str]);
  const balance_ede_edeDistribution = useMemo(() => {
    if (!edeDistribution_str) return;
    return toFromBN(edeDistribution_str[0]);
  }, [edeDistribution_str]);
  const balance_old_ede_edeDistribution = useMemo(() => {
    if (!oldedeDistribution_str) return;
    return toFromBN(oldedeDistribution_str[0]);
  }, [oldedeDistribution_str]);

  const balance_ido = useMemo(() => {
    if (!ido_str) return;
    return toFromBN(ido_str[0]);
  }, [ido_str]);

  const balance_ede_burn = useMemo(() => {
    if (!ede_burn_str) return;
    return toFromBN(ede_burn_str[0]);
  }, [ede_burn_str]);

  const balance_ede_EDE_BUSD = useMemo(() => {
    if (!balance_ede_EDE_BUSD_str) return;
    return toFromBN(balance_ede_EDE_BUSD_str[0]);
  }, [balance_ede_EDE_BUSD_str]);

  const circulatingSupply_common = useMemo(() => {
    if (
      !totalSupply_ede ||
      !balance_ede_TokenVesting_EDE ||
      !balance_LP_Farm ||
      !balance_ede_LP_Marketing ||
      !balance_ede_Contracts_Reserve ||
      !balance_ede_burn ||
      !balance_ede_TokenVesting_pEDE ||
      !balance_ede_TokenVesting_pEDE2 ||
      !balance_ede_edeDistribution ||
      !balance_EDE_DAO
    )
      return;
    const data = totalSupply_ede
      .minus(balance_ede_TokenVesting_EDE)
      .minus(balance_LP_Farm)
      .minus(balance_ede_LP_Marketing)
      .minus(balance_ede_Contracts_Reserve)
      .minus(balance_ede_burn)
      .minus(balance_ede_TokenVesting_pEDE)
      .minus(balance_ede_TokenVesting_pEDE2)
      .minus(balance_ede_edeDistribution)
      .minus(balance_EDE_DAO);
    return data;
  }, [
    balance_EDE_DAO,
    balance_LP_Farm,
    balance_ede_Contracts_Reserve,
    balance_ede_LP_Marketing,
    balance_ede_TokenVesting_EDE,
    balance_ede_TokenVesting_pEDE,
    balance_ede_TokenVesting_pEDE2,
    balance_ede_burn,
    balance_ede_edeDistribution,
    totalSupply_ede,
  ]);

  const circulatingSupply = useMemo(() => {
    if (chainID == 56) {
      if (
        !circulatingSupply_common ||
        !balance_ede_TokenVesting_EDE_old ||
        !balance_old_ede_edeDistribution
      )
        return;
      const data = circulatingSupply_common
        .minus(balance_ede_TokenVesting_EDE_old)
        .minus(balance_old_ede_edeDistribution);
      return data;
    } else if (chainID == 42161) {
      if (
        !circulatingSupply_common ||
        !balance_ede_TokenVesting_EDE_old ||
        !balance_old_ede_edeDistribution ||
        !balance_ido
      )
        return;
      const data = circulatingSupply_common
        .minus(balance_ede_TokenVesting_EDE_old)
        .minus(balance_old_ede_edeDistribution)
        .minus(balance_ido);
      return data;
    } else {
      if (!circulatingSupply_common) return;
      const data = circulatingSupply_common;
      return data;
    }
  }, [
    chainID,
    circulatingSupply_common,
    balance_ede_TokenVesting_EDE_old,
    balance_old_ede_edeDistribution,
    balance_ido,
  ]);

  const circLocked = useMemo(() => {
    if (!totalStaked_ede || !circulatingSupply) return;
    return totalStaked_ede.div(circulatingSupply);
  }, [circulatingSupply, totalStaked_ede]);

  const avgLockTime = useMemo(() => {
    if (!totalSupply_gede || !newtotalSupply_ede) return;
    return totalSupply_gede.div(newtotalSupply_ede).minus(1).div(3).times(0.5);
  }, [newtotalSupply_ede, totalSupply_gede]);
  return useMemo(() => {
    return {
      totalSupply_ede,
      totalStaked_ede,
      circulatingSupply,
      balance_ede_EDE_BUSD,
      circLocked,
      avgLockTime,
    };
  }, [
    avgLockTime,
    balance_ede_EDE_BUSD,
    circLocked,
    circulatingSupply,
    totalStaked_ede,
    totalSupply_ede,
  ]);
};
