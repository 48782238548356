import httpClient from "./httpClient";
interface HttpResponse {
  code: number;
  data: any;
}

export const getPrices = (): Promise<HttpResponse> => {
  return httpClient.get("/prices") as Promise<HttpResponse>;
};

export const getPrices_gns = (): Promise<HttpResponse> => {
  return httpClient.get("/gnsprices") as Promise<HttpResponse>;
};
