import { useDispatch } from "react-redux";
import { getPrices, getPrices_gns } from "src/services/api";
import { useAppSelector } from "../hooks";
import { priceList, priceList_gns } from "./actions";
interface Response {
  code: number | string;
  data: any;
}
export function useQueryPrice() {
  const dispatch = useDispatch();
  return () => {
    getPrices().then((res: Response) => {
      const data = res || {};
      dispatch(priceList(data));
    });
  };
}

export function usePrices() {
  return useAppSelector(({ http: { price } }) => price);
}

export function useQueryPrice_gns() {
  const dispatch = useDispatch();
  return () => {
    getPrices_gns().then((res: Response) => {
      const data = res || [];
      dispatch(priceList_gns(data));
    });
  };
}

export function usePrices_gns() {
  return useAppSelector(({ http: { price_gns } }) => price_gns);
}
