import React from "react";

import cx from "classnames";

import "./Tab.css";
import { ReactComponent as LongImg } from "../../assets/images/long.svg";
import { ReactComponent as ShortImg } from "../../assets/images/short.svg";
import { ReactComponent as SwapImg } from "../../assets/images/swap.svg";

const SWAP_ICONS = {
  'Long': <LongImg className="icons" />,
  'Short': <ShortImg className="icons" />,
  'Swap': <SwapImg className="icons" />,
};
export default function Tab(props) {
  const { options, option, setOption, onChange, type = "block", className, optionLabels, icons } = props;

  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };
  return (
    <div className={cx("Tab", type, className)}>
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
        return (
          <div className={cx("Tab-option", "muted", { active: opt === option, [label]: opt === option })} onClick={() => onClick(opt)} key={opt}>
            {icons && SWAP_ICONS[opt] && <span className={cx("tab_icon", { [label]: opt === option })} > {SWAP_ICONS[opt]} </span>}
            {label}
          </div>
        );
      })}
    </div>
  );
}
