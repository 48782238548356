interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  97: {
    NATIVE_TOKEN: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    WBNB: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    BTC: "0x34A601cb81ffE8941e24f303306b13A859CCeE13",
    ETH: "0xDf1BD718CABB900bc6f705ed8b9c9F568e3D3AD7",
    BUSD: "0x83aD7A3C55f40a00B53726dC114443d245d9ec48",
    USDT: "0x96562f0FB4f7a8e1F7F3fFa1A685C476BF4815b4",
    DOGE: "0x4443DA4293db262AA4dDB2bc65006f2e7b2E3cE1",
    XRP: "0x3A31dC5B6b691f5585a7Ae88A19bb9Eb2e441C67",
    ADA: "0xbAe6d2daa895C15eBC0bB1E3af393797f2c77C1b",
    LINK: "0xbe263962BE1bB42579788F671f523A9917eA84C3",
    CAKE: "0xee967d773c257fC333a8133c478e9DE831D3Cb3C",
    DOT: "0x5006914a9Ed46B68f2Ea2C71CA0dd395Bf1530C3",
    FIL: "0xb3AE87b0ba8b5F7f1Ae4A2f46131D6e891053E24",
    GMT: "0x44F6B5E8925CAcC923a1b6A744CE3337F07d326c",
    INJ: "0xD79a6BC7D373eAeE5Ee10c8568Cb9d554C68cF3B",
    LTC: "0x42394ECe13776A1c92F28732AB870a5640B60Bee",
    MATIC: "0x4Dd4ffE010E6A50CBF53C49a061D6b1c44cc0E54",
    MBOX: "0xDFCCfa814e092D892F1b9AE03715E3b548dcE8B8",
    REEF: "0x9f0FAf7EAA50aaCfE537dddd5e5f10Cd487dfB6E",
    SXP: "0xeef32719C87eA0e71925f6cdc37A2E1D684F770B",
    TRX: "0x63aDCec442398d3d10733A8EA12D0Cb944D9Bc3a",
    XAU: "0x1f015c77471CdF23635eBFCD2175102f0d8730AF",
    XVS: "0xd02CC3e48e75DA7E954f5Dce010B679C0f066403",

    chainlinkUSDT: "0xEca2605f0BCF2BA5966372C99837b1F182d3D620",
    chainlinkBUSD: "0x9331b55D9830EF609A2aBCfAc0FBCE050A52fdEa",
    chainlinkBTC: "0x5741306c21795FdCBb9b265Ea0255F499DFe515C",
    chainlinkBNB: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
    chainlinkWBNB: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
    chainlinkETH: "0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7",
    chainlinkXRP: "0x4046332373C24Aed1dC8bAd489A04E187833B28d",
    chainlinkDOGE: "0x963D5e7f285Cc84ed566C486c3c1bC911291be38",
    chainlinkDOT: "0xEA8731FD0685DB8AeAde9EcAE90C4fdf1d8164ed",
    chainlinkLINK: "0x1B329402Cb1825C6F30A0d92aB9E2862BE47333f",
    chainlinkCAKE: "0x81faeDDfeBc2F8Ac524327d70Cf913001732224C",
    chainlinkADA: "0x5e66a1775BbC249b5D51C13d29245522582E671C",

    chainlinkFIL: "0x17308A18d4a50377A4E1C37baaD424360025C74D",
    chainlinkGMT: "0x0EAeCDf64C9e8dC709d3B453f921D97c02B4515F",
    chainlinkINJ: "0x58b299Fa027E1d9514dBbEeBA7944FD744553d61",

    chainlinkLTC: "0x9Dcf949BCA2F4A8a62350E0065d18902eE87Dca3",
    chainlinkMATIC: "0x957Eb0316f02ba4a9De3D308742eefd44a3c1719",
    chainlinkMBOX: "0x920667ED2615bc053A4b156aCD48919D83F997cF",

    chainlinkREEF: "0x902fA2495a8c5E89F7496F91678b8CBb53226D06",
    chainlinkSXP: "0x678AC35ACbcE272651874E782DB5343F9B8a7D66",
    chainlinkTRX: "0x135deD16bFFEB51E01afab45362D3C4be31AA2B0",
    chainlinkXAU: "0x4E08A779a85d28Cc96515379903A6029487CEbA0",
    chainlinkXVS: "0xCfA786C17d6739CBC702693F23cA4417B5945491",

    pyth: "0xd7308b14BF4008e7C7196eC35610B1427C5702EA",
    EDEPriceFeed: "0x4C1943f91Ba0AAfaD2175a7a8Cc59c1baCD07C92",
    VaultPriceFeedV3Fast: "0x60b54747657916780C8a41b15D0F0eEb0ABE0DeF",
    VaultPriceFeedV2Fast: "0x60b54747657916780C8a41b15D0F0eEb0ABE0DeF",

    randomSource: "0xdF07Ab42a4Ad332D06D6a254e2B384Ddb7bcbda5",
    NFTUtils: "0x9e493661543d1Ff10d9a0D62393B2F2B84e84a5F",
    InfoHelper: "0x24f071ac809C5e41672af5869a70FC2D3c3F6544",
    Reader: "0x8da8338bd5cB6432e28391B981c14fe290083419",
    VaultReader: "0x1b431fE2215b99f17985385D9Dc1b0d3110933EA",
    PositionReader: "0x32555e4263B6EE78D15526Fb9fdc356b9678627E",
    OrderBookReader: "0xC972A2d120705daF17b74Ddee9aac76e58442B3b",
    EDE: "0x69B6bEdc416E00c274d486a45399DcbF432a1B9B",
    Timelock: "0x5b73A9bdc99b5CC2C3005e15331aa869AD94acF6",
    alphaEDE: "0x126205981b5c95C5746F9Af451c644b8663EAd3f",
    aEDE: "0x126205981b5c95C5746F9Af451c644b8663EAd3f",
    pEDE: "0x57A8a82E128c55caD3D8A39644E7B09b05db2876",
    EUSD: "0x9B64524C0C4A044c72752A6642aC61648C7B6546",
    RewardRouter: "0x127Fdc896193c9d1c9E76045616E6d0c6C1ba1B3",
    ESBT: "0xFf8dd5c6cAE39E482f2A23bd466E43cD610263CD",
    USDX: "0xa48955De7291D0c322f9DCc76C5bf8A1DDb100c1",

    ELP_1p: "0xC101e0A9d1277cdEa4a730f8D5c662ee6f23E8C2",
    Vault_ELP_1p: "0xf66db2951d8Ed73b65aDD495e732ec63B47714DD",
    VaultUtils_ELP_1p: "0x11DDE422a95D89A7e6857F7221e6F4DF2ebf2D4D",
    VaultStorage_ELP_1p: "0xf00e37773F49Dd351f5d44102900995c068187fC",
    Router_ELP_1p: "0x1A96BE18f864Bcb79973F0bD9FA098CC5c94C366",
    OrderBook_ELP_1p: "0xFcf8535486b1352D3c4A53684f16731852AC642E",
    PositionManager_ELP_1p: "0xD5D4c203fd05A210049D0fcB444115Bf10aDD8ab",
    PositionRouter_ELP_1p: "0x9aCAd789C765c8d7aD59770005e4A9376F5A48b1",
    ElpManager_ELP_1p: "0x03C2964Bdd0c398827332228C08EBd59Ac018481",
    RewardTracker_ELP_1p: "0x2F91218051C30A0BC452e36212ae22ace721a666",
    RouterSign_ELP_1p: "0x93B9F92E74424e6bDAa3E672C28791431C0F6Fa4",

    ELP_3: "0xFe0b130c744233cefbEAE2Eacf6E39CAD3dC690A",
    Vault_ELP_3: "0xDfbC7F1a8bc4b262F51E2DCD59E59BA5192A2f22",
    VaultUtils_ELP_3: "0x89BB1b06969337E0E80046DAaBE3bCf6519e9Ec6",
    VaultStorage_ELP_3: "0xe96a56D3A97F87f9E9968B491f807245A98278B6",
    Router_ELP_3: "0x433dF5e827a58e4c1632aD17062Be785656446F0",
    OrderBook_ELP_3: "0x7f9879C1a4Dc8c4481F90f0B7D2DB4F934DF6B47",
    PositionManager_ELP_3: "0x1612aa9AC9aAEEde005B583422Eb5fC40443dfE3",
    PositionRouter_ELP_3: "0x25ce90160765208225f7cE7EBd0eaEb9Da3e43Eb",
    ElpManager_ELP_3: "0xbbaCED0e3e8BECe078F1CC04B1abe4Db5603Bb80",
    RewardTracker_ELP_3: "0x023CD10473941d4c492ed0679F6E2C635A219a80",
    RouterSign_ELP_3: "0xF6b28A1c32028A2C40509860a729c1e6c841B85d",

    // stakePool

    veStaker: "0xfE677fb07f79aD69b95B806524f001a87a1f5402",
    edeDistribution: "0x14AAd00089C0fBD352FBFd7e2EA79bD3e8Eae28F",
    eusdDistribution: "0xd14a2DD9B4D138116Ab0e5466cd2C1996DAA69FE",
    stakeHelper: "0x8C23A1AF2D6911d6FBAd78fc265e3da1421D1796",
    TokenVesting_EDE: "0x30F3f7F760D3700aD68229fB53A88F1DcFB3126B",
    TokenVesting_pEDE: "0x9B37cc2Ec57B87C05879Ae8Eb487eCc9c64f9F01",

    RewardRouterHelper: "0x3a78EFa921AFf33c1D79A2e11bb1C0B483f30843",
    BurnToScore: "0x84830d80bFC7c48297fae1cBA046eFFf25E9B075",
    TradeRebate: "0x3Dc52725b0c12F98782B651Cce0F311385C171eE",
    faucet: "0xC47503aF9C1c672A7c0Aa434981dA99d68bcFA73",

    Team: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    ede_burn: "0x0000000000000000000000000000000000000001",
    Marketing: "0x0000000000000000000000000000000000000001",
    TokenVesting_pEDE2: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x0000000000000000000000000000000000000001",

    EDE_DAO: "0x0000000000000000000000000000000000000001",
    edeLpToken: "0x6843429e0ee58220443521A1d6Ae7e7dc80dc8f3",
    Treasury: "0x407ec2A736c40947669db14447df8f2F3c3267D5",
    EDET: "0x468bd4Fcb57eaEB39d5565Df15F26A37e43306AD",
    TreasuryResv: "0x5916eBC418d58042626800Bc17e9a33006ED18f1",
    EDEStaking: "0x893716b8c46160f294fFAA9cA16aeBb0bd8CbB6F",
    RedeemEDET: "0xE6D813b4bdb7Ae6d49a62D9E6CFea6697634b806",
    EUSDRouter: "0xa8491161B550313848dB0deA281f868442842A54",
    InstStaking: "0xFa99ece0b8E5ca8c34f09d658DE6a9Fe568d6312",
  },
  56: {
    deployer: "",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    BTC: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    DOGE: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
    DOT: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
    XRP: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
    MATIC: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    CAKE: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    LINK: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
    ADA: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    chainlinkBUSD: "0xcbb98864ef56e9042e7d2efef76141f15731b82f",
    chainlinkBTC: "0x264990fbd0a4796a3e3d8e37c4d5f87a3aca5ebf",
    chainlinkBNB: "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee",
    chainlinkWBNB: "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee",
    chainlinkETH: "0x9ef1b8c0e4f7dc8bf5719ea496883dc6401d5b2e",
    chainlinkXRP: "0x93a67d414896a280bf8ffb3b389fe3686e014fda",
    chainlinkDOGE: "0x3ab0a0d137d4f946fbb19eecc6e92e64660231c8",
    chainlinkDOT: "0xc333eb0086309a16aa7c8308dfd32c8bba0a2592",
    chainlinkLINK: "0xca236e327f629f9fc2c30a4e95775ebf0b89fac8",
    chainlinkCAKE: "0xb6064ed41d4f67e353768aa239ca86f4f73665a1",
    chainlinkADA: "0xa767f745331D267c7751297D982b050c93985627",
    vaultPriceFeedV2Fast: "0x155B1D516b6dd0F9A69f892c02dB443E7f7Cf9FF",
    pEDE: "0xaa4395A1879D51607944610E23eD53BffE3803a5",
    aEDE: "0x43F649919f4ac48874D7f65D361702E4447Dec0c",
    EDE: "0x4136129Ac3aC90cF9817548b24D35E73E9457e05",
    EUSD: "0x691390b8505821e9f62f7F848dD7C20d5205a58F",
    InfoHelper: "0xF0E9bC99C29CE532e764791d4Bcfc86e976d2d84",
    Reader: "0x86aF1E551C081eC2269f62708C291af1627fA4ED",
    VaultReader: "0x931878ae8A049B8a9116d455911543735E0F09cB",
    OrderBookReader: "0xe1ca1D89612c5df6Ec37204164AD655366Ec3f9c",
    ESBT: "0x70d9c5473D8cFf115E0213B20fd2eEE61F46F877",
    InfoCenter: "0xB00885eef0610C1A9D0f4c125Abe959B63F6B2BF",
    RewardRouter: "0x2108397905F6d3A9b277c545948C6d6E1Ca22D06",
    RewardRouterHelper: "0xda7a91447D4c0842B395570C830886C5646Fd726",
    Timelock: "0x12A8aeF0f1B3af754359bb6Df0F6eE19C58E330D",
    USDX: "0x2485B41075542aadbA0A5cbBA369678bC7A94c0e",
    ElpTransferHelper: "0xb69bA67a007F73eC37db2ea16BEF9386e25B980f",

    ELP_1p: "0xC25baf0828B4dEb0a9bC7CC918cFA16BA875a4be",
    Vault_ELP_1p: "0xF1D7e3f06aF6EE68E22baFd37E6a67b1757c35a9",
    VaultUtils_ELP_1p: "0xba97F52714c80C4642FAD2EbC10486f7AA0C5e1d",
    VaultStorage_ELP_1p: "0x693C116Ae5dA43EB08dda6954416b3144085880C",
    ElpManager_ELP_1p: "0xFaF4bc3791B7B2133564155482abd190d971f055",
    RouterSign_ELP_1p: "0x6B0513cDFfD0c74C6aC6B44776d0ef388dC8D751",
    Router_ELP_1p: "0x9695862790E00A4cb80f474103078d1EEEe916fA",
    OrderBook_ELP_1p: "0x001D4da8A1DCC9B44f3E9b4Af91f1F603D04cC08",
    PositionManager_ELP_1p: "0x59F4aC9fd1f465Af1dE49d0Fbe90c7696281F43A",
    PositionRouter_ELP_1p: "0x19445971fA50014E5434b0F9B8929730eFbA44Fa",
    RewardTracker_ELP_1p: "0x43c1FcC7F4E604F7DA57bA58Bb2A8E7d9cc48B21",

    ELP: "0x28b31aB12A285648505568897D55778174BEDa1E",
    Vault: "0x7f90C8De425e2E21F6d152e881713DE5Fe37dEAB",
    VaultUtils: "0x3dfC44Ca476527Eb03D7f4B334ac958b201c8019",
    Router: "0x6F9AaE42c4bfa604f91795dD50aEd643766aFb84",
    OrderBook: "0xF08a5Cc5f9432f684A30E9aADE3d51A516b56178",
    PositionManager: "0x7E1C4a0D16a56bC05F03BE39812E6b4A75c8A963",
    PositionRouter: "0xDeA8e4910872B0761262b67CaFF365d98F7911BE",
    ElpManager: "0xa0297244063a75AB2fd1457DCbb2A585fC82fc03",
    RewardTracker: "0x68DfAEE9D90F6B7B7Cd643Cd179849C036Ae3FF7",

    ELP_2: "0x1C5AFF95D1C701284E595A8bE675Cf6d474C0069",
    Vault_ELP_2: "0x2c7077cF9bd07C3BC45B4E5b8C27f8B95c6550B3",
    VaultUtils_ELP_2: "0x08633Ff2b893563a12f53e4a23aaf617318a46B4",
    Router_ELP_2: "0x7569dF80D47321f2bAFEDba0De5302761940eeC9",
    OrderBook_ELP_2: "0x80E1BbAddAb5A13Cc1d6954041B9002986Ec24C8",
    PositionManager_ELP_2: "0xF19f56C73B69caFeD9c854aDDDB16cB2bc350cfa",
    PositionRouter_ELP_2: "0xb8cF970D76E473973b8DA6FD1A4B9294a5C49c81",
    ElpManager_ELP_2: "0x260ceD7C2233BB3E4512b20B4eA3351D155Dc517",
    RewardTracker_ELP_2: "0x90fb9475BAf9BbCC3ea3468F5C2a9D3A6001032F",

    ELP_3: "0x0994D33fFe9eBD328E99faF4c93765e97Dc11bCF",
    Vault_ELP_3: "0x7d96c0E7b7D76803769a6a87B90aC8Ba4ec5C38D",
    VaultUtils_ELP_3: "0xAC7664805D65B0F9998103Cd5754216605f25ae8",
    VaultStorage_ELP_3: "0xAa6B757d4D9241427C6573B13381cEA04B3373fC",
    Router_ELP_3: "0x689B62cE162827D7352956C7b538eAc0b92E5808",
    OrderBook_ELP_3: "0x33A0C8dd2b8ab119C793735Ae70C7916c2B0C72F",
    PositionManager_ELP_3: "0x788f2864E7fE29E203522D9e3f1888EBaf477ac5",
    PositionRouter_ELP_3: "0x5B6D3F0Fe78335a020F63887b53E49702D8DB97e",
    ElpManager_ELP_3: "0xB509a49Eafa818dC6d2B56F8aa64C723024EE08C",
    RewardTracker_ELP_3: "0xF165d8C4a24985A8FA5e06C637F4b607B3f24761",
    RouterSign_ELP_3: "0x6FfbE6aC24aA7FCddcB1c3d4AdF794B2df0cb65E",

    veStaker: "0x529F7A90f163716662F2e6b07eEFEaBC5f18e53f",
    totalStaker: "0x49F82424a0E79615198e2991eeF3946c0F294B7A",
    edeDistribution: "0xDAbC331ccb0fE199C71375558c8EEa426ba848BB",
    eusdDistribution: "0x11582118FB65788E3a1465d301F289F93d55079c",
    stakeHelper: "0x1669160E965B6215D7DdF9Db7439b6c547E8b947",

    oldveStaker: "0xe77cA7549567d9b231238977646009b25D2aa4bB",
    oldedeDistribution: "0xFAB1c6C270E9297d4cE79A036d5C1772d844f2C8",
    oldeusdDistribution: "0x00d2dB2B4F30b1F77bDEb57C8Aa89D1732FD607D",
    oldstakeHelper: "0x36A294739Ca7865CC7CE496089F2621273C300f1",
    TokenVesting_EDE_old: "0x6517c8Ad9A160476c325d065a213D6A887263B07",
    TokenVesting_EDE: "0x236c6B12ff166E428ceB4970Af62bcC7e21bd3b7",
    TokenVesting_pEDE: "0x7ACc245ddBFb3d8beFD4AEfc5B1b687e776180dC",
    randomSource: "0x9ecb49340c3f29e1517244579782b8b693f9b28b",
    ShareholdersRebate: "0x8027C3155ab9bb0b663e9d835A68f3Abad7aD20c",
    NFTUtils: "0x44D8a4e73A25860d9d61Cb8e0Efc48701656eE82",
    DataFeed: "0x23Cfbf835e6D4Ab8A45dCa7115869e88cbA5957D",
    FundCreator: "0x2BAe69F0d73241E20b477e4b6e87E964b84E2EB7",
    FQUtilCreator: "0xCCD3891c1452b7CB0E4632774B9365DC4eE24f20",
    EDE_LP: "0xD14E0b9EbA0010e97f57CBd9042215499b53Bc47",
    TradeRebate: "0x5CE96AEc9E8DF812bF1Df6925Ca56C2d57B051ac",
    PositionReader: "0x53198bC825Bcd438D181F2902eFbC4Ba1ce2D62E",
    RouterSign_ELP: "0x6B0513cDFfD0c74C6aC6B44776d0ef388dC8D751",
    Team: "0x51e496e5e9f33a055655c275f0BA9f550771D921",
    LP_Marketing: "0x3EB04DDD24D232666c0992491f33DBD09Fc0440F",
    Contracts_Reserve: "0x1Dc4396496a809788F1dCD4F19B8515d90FCBE70",
    ede_burn: "0x000000000000000000000000000000000000dEaD",
    Marketing: "0xAF36E340E901c9864f7f215B9DeAc00aa87e4ea3",
    TokenVesting_pEDE2: "0x2bB5FBDB9045E8b6f7b08B89E02545D780A8b421",
    BurnToScore: "0x9A70AB073A7Bd9129D1F9071735789a6473cC467",
    LP_Farm: "0x0000000000000000000000000000000000000001",
    EDE_BNB_LP: "0xa8cd0cd8fb6277d97813a49baaf50106fe88b923",
    EDE_aEDE_LP: "0xf5A037Eaeb4a5C5841A251422A0f1016DD24A2A4",
    EDE_DAO: "0x3AFcEA087229f08F60556776c3b00d5606129247",
    Treasury: "0x5741e8fFAD3deBd4e2e92cd012FC6CBFFEcc0dF2",
    edeLpToken: "0xa8cd0cd8fb6277d97813a49baaf50106fe88b923",
    EDET: "0x90a40a3AF115D38226C66D30127053C3A030C3Ed",
    TreasuryResv: "0x558cBeB0568599786EED001e895Bf1b5233a2480",
    EDEStaking: "0xcb896fE296A5cb3f0dD99522BdE4112753Eb3F99",
    RedeemEDET: "0x67158796eD9eFcEc32c2Fa75F523904D2824485e",
    InstStaking: "0xFd6551EbcF94D9cB027C04ACC81bfC3015773b1A",
  },
  421613: {
    deployer: "0x9fC9bd6a1605e78bA4556f0098442aB93E78FC0D",
    NATIVE_TOKEN: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    WETH: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    BTC: "0x16Fa53e7C585377Eb020622B73FbACBa88ba3a2b",
    USDC: "0x470AC774bb8327Fde0fa0565e2680540B28A3341",
    USDT: "0x50aAF50E4DA16aa5b1F12eAF48a62d093CAfd61E",
    DAI: "0x0c9dF6B22DcC519B7e1073B2C3199B69E1994a59",
    chainlinkBTC: "0x6550bc2301936011c1334555e62A87705A81C12C",
    chainlinkDAI: "0x103b53E977DA6E4Fa92f76369c8b7e20E7fb7fe1",
    chainlinkETH: "0x62CAe0FA2da220f43a51F86Db2EDb36DcA9A5A08",
    chainlinkWETH: "0x62CAe0FA2da220f43a51F86Db2EDb36DcA9A5A08",
    chainlinkLINK: "0xd28Ba6CA3bB72bF371b80a2a0a33cBcf9073C954",
    chainlinkUSDC: "0x1692Bdd32F31b831caAc1b0c9fAF68613682813b",
    chainlinkUSDT: "0x0a023a3423D9b27A0BE48c768CCF2dD7877fEf5E",
    VaultPriceFeedV2Fast: "0x5de4EC36511B638293D708B1669a484d906Ab925",
    randomSource: "0x9F653079A849aC67E5C8E6220Fc0Aa95b05E553b",
    NFTUtils: "0x4b07844ccE366806E8772b462b09d6361a87c263",
    InfoHelper: "0xED227229202780E84F05c7B4431911A9A31a62F3",
    Reader: "0xAad4C29f08ea791abEA14ea8707ae05E6CF9820A",
    OrderBookReader: "0x48a4192E8650D527295C3CDBa3E6fcccf1bC449B",
    VaultReader: "0x98B2BE6e3cfb1058Dc1345aF94990eadc86BEccA",
    PositionReader: "0x8d09Aa994D788a10Be972b61875b7eDACf04A549",
    Timelock: "0xC886Bf36d3e422df347CAbfd3215AEd02328D8aE",
    USDX: "0x713136320704AEee48d0dC2772D01260932d4506",
    EDE: "0xB2F97c0f066893E56A9Db65987C98Fb53e412072",
    alphaEDE: "0xb135fe02078556324551F27F3699cfb6cd80827e",
    aEDE: "0xb135fe02078556324551F27F3699cfb6cd80827e",
    pEDE: "0x795C381cc335C092B322c94Ffa440784f4Fc6376",
    EUSD: "0x76e5c718c29d52b4D66aEBC05C5C3Db6e0bA664C",
    RewardRouter: "0x565B265277574ED66715419c4767f0953265fe50",
    ESBT: "0xF2A514f497C0cbBC7dAc6f1931b25F7B5394f299",
    ELP: "0x5D237C21C2f44722bC037F1AE9Ddbe66bF28FEbd",
    Vault: "0x36d41b6fF22EC7959f910d4F26C4Fbe6e5D6Ac4E",
    VaultUtils: "0x2FEc7c700308Fc98671DA41ba793275035B5df77",
    VaultStorage: "0xb113A7cEa58568ABdd0553Df4c8bB9B23b042276",
    Router: "0x6Df901183841f2F53a3F78Bf8e57A20c6d460fb0",
    OrderBook: "0xCB67C521340a5Bb6DF420aC2289e7aBB7a4e9025",
    PositionManager: "0xb898c5F960F3eFb5d5177620fBfE5bf7da0b6825",
    PositionRouter: "0x30dA900dB2D2d157762efa3efF6956d40Ca09EaF",
    ElpManager: "0x4ee868Da9eED11EB0E666CDB0B81731CbdE21748",
    RewardTracker: "0x072958D4930Cf118fe3320d04c9e0a02f1cfc918",
    veStaker: "0x3f3b52b7E25C4c64A97747960c766225BA1015C0",
    edeDistribution: "0x1607AeeCEEb665A0623fF7c7810B67Fb09EF677c",
    eusdDistribution: "0xB870777498e54296100435E3402f02e37322E95B",
    stakeHelper: "0x1291C3f68C2f0619622337EEA2Aa8c8A956E9F1b",
    TokenVesting_EDE: "0xa49daB0C49c4fE83F033Af152A32D3e863e8bB86",
    TokenVesting_pEDE: "0x3D65cE3DeD32307dB91E656a807297B3Fb74E031",
    RewardRouterHelper: "0x27C5721B4BF3c690f2c42e33B128550AAaA2889E",
    BurnToScore: "0x7f571b26721579a78F3Aa3536544F2D2D9B68570",
    TradeRebate: "0xE414afBFa3185a5b1257545B9916222Ba083b8b9",
    faucet: "0x59cedd4E2B5425e784487F3bf4f898693BdCBa80",

    EDE_DAO: "0x0000000000000000000000000000000000000001",
    EDE_LP: "0xaEe316F5Fd919ED7347597Ee5B93d76917a02e48",
    Team: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    ede_burn: "0x0000000000000000000000000000000000000001",
    Marketing: "0x0000000000000000000000000000000000000001",
    TokenVesting_pEDE2: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x0000000000000000000000000000000000000001",
  },
  42161: {
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    WETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    BTC: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
    USDC: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    USDT: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    DAI: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    ARB: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    DOGE: "0x155f0DD04424939368972f4e1838687d6a831151",
    LINK: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
    MATIC: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    ADA: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    DOT: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
    chainlinkBTC: "0x6ce185860a4963106506c203335a2910413708e9",
    chainlinkDAI: "0xc5c8e77b397e531b8ec06bfb0048328b30e9ecfb",
    chainlinkETH: "0x639fe6ab55c921f74e7fac1ee960c0b6293ba612",
    chainlinkWETH: "0x639fe6ab55c921f74e7fac1ee960c0b6293ba612",
    chainlinkUSDC: "0x50834f3163758fcc1df9973b6e91f0f0f0434ad3",
    chainlinkUSDT: "0x3f3f5df88dc9f13eac63df89ec16ef6e7e25dde7",
    VaultPriceFeedV2Fast: "0x046600975BED388D368F843A67E41545e27A2591",
    randomSource: "0xc64e1b4D46d431De799660171f6992c23e321DB9",
    NFTUtils: "0xd25ce0f789A58Eb5DC3Fa1cA9963D83b673800b7",
    InfoHelper: "0x16a6bd70684c0626bc3c69eF62EF5860849df851",
    Reader: "0x44D8a4e73A25860d9d61Cb8e0Efc48701656eE82",
    VaultReader: "0x12B0fc0fA0f2f8Df188681effc6E7fE363aE2283",
    PositionReader: "0x0C92e9E401377E3d4B6E5C3C59f2aD24dB255734",
    OrderBookReader: "0x2BAe69F0d73241E20b477e4b6e87E964b84E2EB7",
    Timelock: "0x0142FeF53eBB44Ed2C00c16478DC619fc3e64321",
    EUSD: "0xB00885eef0610C1A9D0f4c125Abe959B63F6B2BF",
    RewardRouter: "0x86aF1E551C081eC2269f62708C291af1627fA4ED",
    ESBT: "0x197E2e5a16F63dBB7BB2AE55975ff49b835f11e2",
    aEDE: "0x5566d132324181427eD4f46989121030BC6689C7",
    alphaEDE: "0x5566d132324181427eD4f46989121030BC6689C7",
    pEDE: "0x496900EabcDF5544507ED29Cda3c7f9d0aCE7208",
    EDE: "0xCCD3891c1452b7CB0E4632774B9365DC4eE24f20",
    USDX: "0xC8b346Cd04D367bbb234732F36Ee6a5bE6a54c8f",
    ELP: "0xEc08B5a75473FD581BE6628d4e2Ed08b49078dF0",
    Vault: "0xFC36bE177868b05F966E57bfc01617501b1f6926",
    VaultUtils: "0xb23Cd479195c3Ad24c569C5e1f8641Da5a4b3CE4",
    VaultStorage: "0x5655fBeb5e066AE5fcCe763ed21daC39Bd3B144C",
    Router: "0x2c7077cF9bd07C3BC45B4E5b8C27f8B95c6550B3",
    OrderBook: "0x08633Ff2b893563a12f53e4a23aaf617318a46B4",
    PositionManager: "0x7569dF80D47321f2bAFEDba0De5302761940eeC9",
    PositionRouter: "0x80E1BbAddAb5A13Cc1d6954041B9002986Ec24C8",
    ElpManager: "0x26AA71BE9CCD794a4c9043Be026c68496b45Aa73",
    RewardTracker: "0x2108397905F6d3A9b277c545948C6d6E1Ca22D06",

    veStaker: "0xDAFFD168bdAc2CfE3B52d3050b4F743B42D00d91",
    totalStaker: "0x5d47e35FC6A1f3e22a5cD1Acd5bAb918Be49F531",
    edeDistribution: "0x86fE7bDb5fc80B33474B7572790436c3bD3df30E",
    eusdDistribution: "0x586aF3A3D29B5CE55226369c1De102D899E33D0c",
    stakeHelper: "0xc7d1489611726553A28ACAff05fdb2ca452fdD10",

    ELP_3: "0x038cC240A4337a5758700235f4efA3fCd1B521F6",
    Vault_ELP_3: "0x24b6137A5fe9d058baf654bb73aB857F57DF8BB4",
    VaultUtils_ELP_3: "0x6064DCb3CCc4Bb33e6E4F5C20D126aB15fA14f0d",
    VaultStorage_ELP_3: "0x9DC362DB8B1C1034a8b34551ece88ded9b40Feee",
    Router_ELP_3: "0x92EF7A443a1a2EAd17e1EBB7E1613efdb8c3B41c",
    OrderBook_ELP_3: "0x5c1D800Bf2154fe78018e12Ab0b63E94ed23eebc",
    PositionManager_ELP_3: "0x75b814ab2d7E1144d8deba303B024e8fDfE599ec",
    PositionRouter_ELP_3: "0x63587f908681b9200c14A22cF6B53935E665Eae9",
    ElpManager_ELP_3: "0x985A1608E8096EA9bebf2100D2319CE7F7F3b7Ae",
    RewardTracker_ELP_3: "0xDfE6BB98833dae0f5cD76f488Fc7726b140Fc6e9",
    RouterSign_ELP_3: "0x09d196538F9a09a377C38b5C4400a45D113357F6",

    oldveStaker: "0x39Eb2A5196a00186D15c28E9fe35A36fD1e6f06c",
    oldedeDistribution: "0x9321061001f07420bF1F068d46feC6E5485690A2",
    oldeusdDistribution: "0xE3CA515c9C430f2E270E293ab2793070014F720d",
    oldstakeHelper: "0x494b1C5Db70F0e4fE577A53685184B47279C5901",

    RewardRouterHelper: "0x881DB48147c014111ef2aC6587E3cF2d9206171d",
    BurnToScore: "0xe8594Bb25fb88bBefd61D219Ffbd32a0de77b383",
    TradeRebate: "0x1c547fBa2C20BB5d432970947b4849143A8E4fF8",
    TokenVesting_EDE_old: "0x2380B6F99266121FFaF0c457cFAdcd0dc5846560",
    TokenVesting_EDE: "0xAfbd74563922d976625DFa328edFA3a907FB3daC",
    TokenVesting_pEDE: "0x872b327cc9494a31C87134b5162525B7224C525E",
    RouterSign_ELP: "0xD067e4B0144841bc79153874d385671Ea4c4e4DF",
    EDE_LP: "0x7CA686B3795784f12643127c5c3F56aa107a04C3",
    Team: "0x96569ff8f1Eb073f0FFd5cCBf855b7A5431C3B2E",
    LP_Marketing: "0x730c4CD9499033DDb2BF5465098e108089b6F123",
    Contracts_Reserve: "0x00962B56fe689faCC6ec66D38867f1238304B18f",
    ede_burn: "0x000000000000000000000000000000000000dEaD",
    Marketing: "0xAF36E340E901c9864f7f215B9DeAc00aa87e4ea3",
    TokenVesting_pEDE2: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x8B691c7b57E3C7CF69cf2425Ec754C47D8Dc86d7",
    IDO: "0x57b78bff7297635414c55443ff4e3dae8a55a74a",
    EDE_DAO: "0x865cD1dCDdAB2048bb6Ab015a69561854c23Ab6d",
    Treasury: "0xf321de3e71B35E087f2cD5D59F5A2C6621570f2F",
    edeLpToken: "0x7ca686b3795784f12643127c5c3f56aa107a04c3",
    EDET: "0x8eDa8226c679103535A0620E3f32f50C3B8a0d1B",
    TreasuryResv: "0x9e5b541B3404bB833b4590F352361C0dE797e7eE",
    EDEStaking: "0x149D5F5E5d5bcFDaf8EC6eEF33d3556660A31e96",
    RedeemEDET: "0x6B254d95a80a78E33A3e9662F0e313d78d1a11F7",
    InstStaking: "0xE2b7976fF161a96c9864Cc4Decd245Bfb97dfD9a",
  },
};
