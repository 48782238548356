import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { ReactComponent as ArrowDropDownIcon } from "src/assets/images/downicon.svg";
import { DOC_LINK } from "src/constants/misc";
const MyButton = styled(Button)`
  color: rgba(255, 255, 255, 0.25);
  font-size: inherit;
  font-family: inherit;
  width: inherit;
  height: inherit;
  padding: 0;
  text-transform: inherit;
  &:active,
  &:hover {
    color: inherit;
    background-color: inherit;
    border-bottom: inherit;
    .MuiButton-endIcon {
      svg {
        fill: currentColor;
        path {
          fill: currentColor;
        }
      }
    }
  }
  .MuiButton-endIcon {
    svg {
      fill: currentColor;
      path {
        fill: currentColor;
      }
    }
  }
`;
const MyMenu = styled(Menu)`
  .MuiPopover-paper {
    background-color: rgba(45, 34, 104, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(24px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 4px;
  }
`;
const MyMenuItem = styled(MenuItem)`
  color: #eef2f9;
  font-size: 14px;
  font-family: inherit;
  &:hover a {
    color: #f89542;
  }
`;

export const More = [
  {
    link: DOC_LINK.stats,
    name: "Stats",
    target: "_blank",
  },
  {
    link: "#/Governance",
    name: "Governance",
    target: "",
  },
  {
    link: DOC_LINK.documentation,
    name: "Documentation",
    target: "_blank",
  },
  {
    link: DOC_LINK.discord,
    name: "Discord",
    target: "_blank",
  },
  {
    link: DOC_LINK.twitter,
    name: "Twitter",
    target: "_blank",
  },
  {
    link: DOC_LINK.telegram,
    name: "Telegram",
    target: "_blank",
  },
  {
    link: DOC_LINK.medium,
    name: "Medium",
    target: "_blank",
  },
  {
    link: DOC_LINK.github,
    name: "Github",
    target: "_blank",
  },
];
export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MyButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        More
      </MyButton>
      <MyMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="font-weight-6"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {More.map((item) => {
          return (
            <MyMenuItem onClick={handleClose} key={item.name}>
              <a
                href={item.link}
                target={item.target}
                rel="noopener noreferrer"
              >
                {item.name}
              </a>
            </MyMenuItem>
          );
        })}
      </MyMenu>
    </div>
  );
}
