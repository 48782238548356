import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ConfirmBtn = styled(Button)({
  height: "60px",
  lineHeight: "60px",
  maxHeight: "60px",
  width: "100%",
  color: "#FFFFFF",
  fontSize: 18,
  fontFamily: "Poppins-Bold",
  textTransform: "uppercase",
  background: "#f89542",
  boxShadow: "0px 24px 14px rgba(0, 0, 0, 0.16)",
  border: "none",
  borderRadius: "0px 0px 8px 8px",
  "&:hover": {
    background: "linear-gradient(270deg, #ce6b18 0%, #f89542 100%)",
    color: "#ffffff",
  },
  "&:disabled": {
    background: "rgba(255, 255, 255, 0.05)!important",
    color: "rgba(238, 242, 249, 0.5)",
    borderRadius: "0px 0px 8px 8px",
  },
});
