import { useEffect, useMemo, useState } from "react";
import { useVolumeChartGraphUrl } from "src/Api/rechartApi";
import { gql } from "@apollo/client";
import {
  tooltipFormatter,
  tooltipFormatterPercent,
  yaxisFormatter,
  yaxisFormatterPercent,
} from "./config";
import { useWeb3Context } from "src/hooks";
import axios from "axios";
import { PRICE_CHART_URL } from "src/constants/misc";
export function useChartViewState({ controls, data }: any) {
  const [viewState, setViewState] = useState({
    isPercentsView: false,
  });

  const formattedData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    if (viewState.isPercentsView && controls.convertToPercents) {
      return controls.convertToPercents(data);
    }

    return data;
  }, [data, viewState.isPercentsView, controls]);

  const togglePercentView = () => {
    setViewState((old) => ({ ...old, isPercentsView: !old.isPercentsView }));
  };

  return {
    viewState,
    togglePercentView,
    formattedData,

    itemsUnit: viewState.isPercentsView ? "%" : undefined,

    yaxisTickFormatter: viewState.isPercentsView
      ? yaxisFormatterPercent
      : yaxisFormatter,

    tooltipFormatter: viewState.isPercentsView
      ? tooltipFormatterPercent
      : tooltipFormatter,
  };
}

export function useVolumeDataRequest() {
  const query = gql`
    {
      volumeStats(
        where: { period: daily }
        orderBy: "id"
        orderDirection: desc
      ) {
        burn
        id
        liquidation
        margin
        mint
        period
        swap
        cumulative
      }
    }
  `;
  const { chainID } = useWeb3Context();
  const graphClient = useVolumeChartGraphUrl();
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState(false);
  useEffect(() => {
    setData(undefined);
    setError(false);
    graphClient
      .query({ query })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(true);
        console.log("graphql trades err: ", err);
      });
  }, [chainID, graphClient, query]);

  const loading = !error && !data;
  let resp;
  if (data) {
    resp = data.volumeStats.map((volume: any) => {
      const burnAmount = volume.burn / 1e30;
      const liquidationAmount = volume.liquidation / 1e30;
      const marginAmount = volume.margin / 1e30;
      const mintAmount = volume.mint / 1e30;
      const swapAmount = volume.swap / 1e30;
      const cumulationAmount = volume.cumulative / 1e30;

      const tally =
        burnAmount + liquidationAmount + marginAmount + swapAmount + mintAmount;
      return {
        burn: burnAmount,
        liquidation: liquidationAmount,
        timestamp: volume.id,
        margin: marginAmount,
        mint: mintAmount,
        swap: swapAmount,
        cumulative: cumulationAmount,
        all: tally,
      };
    });
  }
  return {
    data: resp,
    loading,
    error,
  };
}

export function usePriceChart() {
  const { chainID } = useWeb3Context();
  const [data, setData] = useState<any>();
  const url = chainID ? PRICE_CHART_URL[chainID] : undefined;

  useEffect(() => {
    function fech() {
      if (!url) return;
      axios.get(url).then((res) => setData(res?.data?.data?.reverse() || []));
    }
    fech();
  }, [url, chainID]);
  return {
    data,
  };
}
