import { Box } from "@mui/material";

type dataPros = {
  label?: string;
  val?: number | string;
};

export default function TooltipItem({ label, val }: dataPros) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      lineHeight="20px"
      className="font-14"
    >
      <Box display="flex" alignItems="center">
        <div className="line"></div>
        <div className="ml-8 color9 ">{label}</div>
      </Box>
      <div className="font-weight-6 text-right color26">{val}</div>
    </Box>
  );
}
