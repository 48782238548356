import { AddressEnvMap, SupportedChainId } from "./chains.base";
export const SUPPORTED_CHAIN_IDS_MAP: AddressEnvMap = {
  [SupportedChainId.BSCMAINNET]: SupportedChainId.BSCMAINNET,
  [SupportedChainId.ARBITRUM]: SupportedChainId.ARBITRUM,
};

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.BSCMAINNET,
  SupportedChainId.ARBITRUM,
];
