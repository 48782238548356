import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ReactComponent as EdePirce } from "src/assets/images/edepirce.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetEdePrice } from "src/hooks/getPrice_ede";
const MyBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
`;
const EdePrice = () => {
  const edePrice = useGetEdePrice();

  return (
    <MyBox className="font-weight-6  color4">
      <EdePirce />${formatAmount2(edePrice || 0, 2, true)}
    </MyBox>
  );
};

export default EdePrice;
